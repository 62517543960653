// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/user/UserAvatar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/user/UserAvatar.tsx");
  import.meta.hot.lastModified = "1728030536059.7163";
}
// REMIX HMR END

import { Avatar, Button, HStack, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { useFetcher, useNavigate } from "@remix-run/react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FaCoins, FaInstagram, FaRegistered, FaUserFriends } from "react-icons/fa";
import { HiLogout, HiOutlineLogin } from "react-icons/hi";
import { isEditor, useAuth } from "~/utils/userUtils";
import moment from "moment-timezone";
const ModalLogout = props => {
  _s();
  const {
    isOpen,
    onToggle,
    onClose
  } = useDisclosure();
  const {
    show,
    onCloseModal
  } = props;
  useEffect(() => {
    if (show && !isOpen) {
      onToggle();
    }
    if (show && isOpen && onCloseModal) {
      onCloseModal(false);
    }
  }, [show, onClose]);
  return <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Du wurdest abgemeldet!</ModalHeader>
        <ModalBody pb={6}>Bis bald ...</ModalBody>

        <ModalFooter>
          <Link href="/">
            <Button colorScheme="pink" mr={3}>
              Startseite
            </Button>
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal>;
};
_s(ModalLogout, "grOBCsh2964SlfiKFszOWG6KEhs=", false, function () {
  return [useDisclosure];
});
_c = ModalLogout;
export const UserAvatar = _s2(forwardRef(_c2 = _s2((props, ref) => {
  _s2();
  const {
    user,
    login,
    logout,
    checkUsershowLogin
  } = useAuth();
  const {
    isOpen,
    onToggle,
    onClose,
    onOpen
  } = useDisclosure();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [username, setUsername] = useState("");
  const [credits, setCredits] = useState("-");
  const [loadUserData, setLoadUserData] = useState(false);
  const [profilePicture, setProfilePicture] = useState(user?.profilePictureThumb);
  const [profilePictureThumb, setProfilePictureThumb] = useState(user?.profilePictureThumb);
  const [ping, setPing] = useState(false);
  const fetcherEventLogin = useFetcher();
  const userFetcherCredits = useFetcher();
  const [instaMode, setInstaMode] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (userFetcherCredits.data) {
      // setLoadUserData(false);
      const creditsSum = userFetcherCredits.data;
      if (creditsSum || creditsSum == 0) {
        // console.log(userFetcherCredits?.data)

        setCredits(creditsSum);
      }
    }
  }, [userFetcherCredits.data]);
  const logoutHandler = e => {
    e && e.preventDefault();
    setUsername("");
    fetch("/logout").then(response => {
      logout();
      navigate("/");
    });
  };
  useEffect(() => {
    if (fetcherEventLogin?.data) {
      const data = fetcherEventLogin.data;
      if (data.id) {
        login(data);
      } else {
        logoutHandler();
      }
    }
  }, [fetcherEventLogin.data]);
  const onToggleCustom = () => {
    onToggle();
  };
  useEffect(() => {
    // console.log("Set User",username)
    setProfilePictureThumb(user?.profilePictureThumb);
    setProfilePicture(user?.profilePicture);
    setUsername(user?.username || "");
  }, [user]);
  useEffect(() => {
    if (isOpen && user) {
      console.log("fetch credits");
      userFetcherCredits.load("/api/credits/" + user.id);
    }
  }, [isOpen]);
  useEffect(() => {
    const lastLogin = localStorage && localStorage.getItem("lastLogin");
    if (user && (!user.lastLogin || lastLogin === null || lastLogin && moment().startOf("D").isAfter(moment(lastLogin)))) {
      localStorage && localStorage.setItem("lastLogin", moment().toISOString());
      fetcherEventLogin.load("/user");
    }
    const instaModeLocal = localStorage && localStorage.getItem("instaMode");
    // console.log("instaModeLocal", instaModeLocal)
    setInstaMode(instaModeLocal == "true");
    return () => {};
  }, []);
  useImperativeHandle(ref, () => ({
    onToggleProfil: () => {
      onToggle();
    }
  }));
  return <Popover isLazy={true} placement="bottom-end" trigger="click" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button variant={"unstyled"} {...props}>
          <Avatar onClick={onToggleCustom} loading="lazy" key={profilePictureThumb} size={"sm"} src={profilePictureThumb} name={user?.username || undefined} boxSize={props.boxSize} />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        {user && <PopoverHeader>
            Hallo <b>{username}</b>
          </PopoverHeader>}
        {!user && <PopoverHeader>Mein Benutzerkonto</PopoverHeader>}
        <PopoverBody>
          <VStack>
            {user && <>
                <Avatar loading="lazy" size={"2xl"} src={profilePicture} name={user?.username || "#"} />
                <Text>Credits: {credits}</Text>
                <Link href="/credits">
                  <Button leftIcon={<FaCoins />}>Credits anzeigen</Button>
                </Link>
                <Link href="/user/settings">
                  <Button leftIcon={<FaUserFriends />}>Einstellungen</Button>
                </Link>
                {isEditor(user) && <Button leftIcon={<FaInstagram />} onClick={e => {
              e.preventDefault();
              localStorage && localStorage.setItem("instaMode", String(!instaMode));
              setInstaMode(!instaMode);
              navigate(0);
            }} colorScheme={instaMode ? "green" : "red"}>
                    Insta mode {instaMode ? "ON" : "OFF"}
                  </Button>}
                <Button onClick={logoutHandler} my={3} leftIcon={<HiLogout />}>
                  Abmelden
                </Button>
              </>}

            {!user && <HStack>
                <Button leftIcon={<HiOutlineLogin />} aria-label="Anmelden" onClick={() => {
              checkUsershowLogin();
            }}>
                  Anmelden
                </Button>
                <Link href="/login/register">
                  <Button leftIcon={<FaRegistered />}>Registrieren</Button>
                </Link>
              </HStack>}
          </VStack>
          <ModalLogout show={showLogoutModal} onCloseModal={setShowLogoutModal} />
        </PopoverBody>
      </PopoverContent>
    </Popover>;
}, "bFnd4iT2sFy1YmL+hEtnZBCdQak=", false, function () {
  return [useAuth, useDisclosure, useFetcher, useFetcher, useNavigate];
})), "bFnd4iT2sFy1YmL+hEtnZBCdQak=", false, function () {
  return [useAuth, useDisclosure, useFetcher, useFetcher, useNavigate];
});
_c3 = UserAvatar;
var _c, _c2, _c3;
$RefreshReg$(_c, "ModalLogout");
$RefreshReg$(_c2, "UserAvatar$forwardRef");
$RefreshReg$(_c3, "UserAvatar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;