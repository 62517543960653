// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/navigation/Navigation.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$(),
  _s5 = $RefreshSig$(),
  _s6 = $RefreshSig$(),
  _s7 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/navigation/Navigation.tsx");
  import.meta.hot.lastModified = "1728030536059.7163";
}
// REMIX HMR END

"use client";
import { ArrowBackIcon, ChevronDownIcon, ChevronRightIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Collapse, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, HStack, Icon, IconButton, Image, Input, InputGroup, InputLeftElement, Popover, PopoverArrow, PopoverContent, PopoverTrigger, Spacer, Stack, Text, VStack, useColorMode, useColorModeValue, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { Link, useFetcher, useLocation, useNavigate } from "@remix-run/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { BsMoonStarsFill, BsSun } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { DealbunnyIcon } from "~/components/customIcons";
import { useSearch } from "~/components/search";
import { UserAvatar } from ".";
import { isEditor, useAuth } from "~/utils/userUtils";
import { useSettings } from "~/utils/settingUtils";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";

// Input-Komponente

const InputComponent = props => {
  _s();
  const {
    onClose,
    onOpen,
    isOpen
  } = useSearch();
  return <InputGroup display={{
    base: "none",
    md: "block"
  }}>
      <InputLeftElement pointerEvents="none">
        <FaSearch />
      </InputLeftElement>
      <Input placeholder="Deal suchen..." autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck={false} maxLength={512} type="search" minW={{
      base: "200px",
      lg: "250px"
    }} onClick={onOpen} onChange={onOpen} disabled={isOpen} bgColor={useColorModeValue("white", "gray.800")} paddingInlineStart={"10"} name="deal_suchen" id="deal_suchen" value={""} />
    </InputGroup>;
};
_s(InputComponent, "wZLjtpDRy9THh4XnBpn5A+LGP6I=", false, function () {
  return [useSearch, useColorModeValue];
});
_c = InputComponent;
export const Navigation = props => {
  _s2();
  const {
    colorMode,
    toggleColorMode
  } = useColorMode();
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);
  const btnRef = useRef(null);
  const {
    user
  } = useAuth();
  const {
    settingByName
  } = useSettings();
  const promoTheme = settingByName("promoTheme");
  const [logo, setLogo] = useState(promoTheme?.data && promoTheme?.data[0]);
  const [logoVisible, setLogoVisible] = useState(false);
  // console.log(promoTheme);

  const showStatusBar = useCallback(async () => {
    await StatusBar.show();
  }, []);
  const setStatusBarStyleDark = useCallback(async mode => {
    await StatusBar.setStyle({
      style: mode && mode == "dark" ? Style.Dark : Style.Light
    });
    return mode;
  }, []);
  useEffect(() => {
    if (promoTheme?.data && promoTheme?.data[0]) {
      setTimeout(() => {
        // Bild wechseln und wieder einblenden
        setLogoVisible(true);
      }, 100); // Zeitverzögerung für das Ausblenden
    }
  }, [promoTheme]);
  useEffect(() => {
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() == "ios") {
      console.log("Set Status bar", colorMode);
      setStatusBarStyleDark(colorMode).then(mode => {
        console.log("cMode", mode);
        showStatusBar();
      });
    }
  }, [colorMode]);
  const bgColor = useColorModeValue("blackAlpha.100", "blackAlpha.600");
  // const bgColorNav = useColorModeValue("blackAlpha.100", "blackAlpha.200");

  const navigate = useNavigate();
  const location = useLocation();
  const isAtRoot = location.pathname === "/" || !Capacitor.isNativePlatform();
  const [isBelowMD] = useMediaQuery("(max-width: 767px)");
  const [isLargerThan768] = useMediaQuery("(min-width: 992px)");
  const {
    onOpen: onOpenSearch
  } = useSearch();
  const [nativeBelowMD, setNativeBelowMD] = useState(Capacitor.isNativePlatform());
  useEffect(() => {
    setNativeBelowMD(isBelowMD);
    setFinishLoading(true);
  }, [isBelowMD]);
  return <Box minH={isAtRoot ? "0" : "60px"}>
      <Box className={"navigation-fixed-bg"} display={"block"} position={"fixed"} width="100%" top="0" left={0} right={0} bgColor={bgColor} zIndex={10}></Box>
      <HStack minH={"60px"} px={isAtRoot ? {
      base: 3
    } : {
      base: 3,
      md: 7
    }} align={"center"} position={isAtRoot ? "relative" : "fixed"} width="100%" top={0} left={0} right={0} bgColor={Capacitor.isNativePlatform() ? useColorModeValue("white", "gray.800") : "transparent"} zIndex={9} className={isAtRoot ? "" : "navigation-fixed"}>
        <Flex flex={{
        base: 1,
        md: "auto"
      }} ml={{
        base: -2
      }} display={{
        base: "flex",
        md: "none"
      }}>
          {isAtRoot && <>
              <IconButton onClick={onOpen} ref={btnRef} icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />} variant={"ghost"} aria-label={"Toggle Navigation"} />
              {!Capacitor.isNativePlatform() && <Button onClick={toggleColorMode} paddingInline={0} aria-label={"dark/light mode"} variant={"ghost"}>
                  {colorMode === "light" ? <BsMoonStarsFill /> : <BsSun />}
                </Button>}
            </>}
          {!isAtRoot && <IconButton variant={"unstyled"} icon={<ArrowBackIcon />} aria-label={"Back"} onClick={() => {
          navigate(-1);
        }} />}
        </Flex>
        <Flex flex={{
        base: 1
      }} justify={{
        base: "center",
        md: "start"
      }} pt={isAtRoot ? 0 : 2}>
          {!isAtRoot && <IconButton display={{
          base: "none",
          md: "flex"
        }} variant={"unstyled"} icon={<ArrowBackIcon />} aria-label={"Back"} onClick={() => {
          navigate(-1);
        }} mr={3} />}
          <Link to="/">
            <Button variant={"unstyled"} pr="3" bgColor={"transparent"} minWidth={100} maxWidth={200} aria-label={"dealbunny Logo"} position="relative">
              <DealbunnyIcon h={"auto"} w={130} transition="opacity 0.3s ease-in-out" opacity={logoVisible ? 0 : 1} />
              {logo && <Image src={logo.image} h={"auto"} w={130} transition="opacity 0.3s ease-in-out" opacity={logoVisible ? 1 : 0} position="absolute" top="2" left="0" />}
            </Button>
          </Link>
          <Flex display={{
          base: "none",
          md: "flex"
        }} ml={{
          base: "5",
          lg: "10"
        }} mr={{
          base: "5",
          lg: "10"
        }}>
            <DesktopNav />
            {isEditor(user) && <IconButton onClick={onOpen} ref={btnRef} icon={isOpen ? <HamburgerIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />} variant={"ghost"} aria-label={"Toggle Navigation"} />}
          </Flex>
        </Flex>
        <Spacer display={!finishLoading ? "block" : "none"} />

        <Flex flex={{
        base: 1,
        md: 0
      }} justify={"flex-end"} gap={{
        base: 2,
        md: 3
      }} display={finishLoading ? "flex" : "none"} pt={isAtRoot ? 0 : 2}>
          <InputComponent />

          {!Capacitor.isNativePlatform() && <>
              {nativeBelowMD && <Box display={{
            base: "flex"
          }}>
                  <Button onClick={e => {
              onOpenSearch();
            }} paddingInline={0} aria-label={"Search"} variant={"ghost"}>
                    <FaSearch />
                  </Button>
                </Box>}
              {!isBelowMD && <Box display={{
            base: "flex"
          }}>
                  <Button onClick={toggleColorMode} paddingInline={0} aria-label={"dark/light mode"} variant={"ghost"}>
                    {colorMode === "light" ? <BsMoonStarsFill /> : <BsSun />}
                  </Button>
                </Box>}
              <UserAvatar />
            </>}

          {Capacitor.isNativePlatform() && <>
              <Box display={{
            base: "flex"
          }}>
                <Button onClick={toggleColorMode} paddingInline={0} aria-label={"dark/light mode"} variant={"ghost"}>
                  {colorMode === "light" ? <BsMoonStarsFill /> : <BsSun />}
                </Button>
              </Box>

              {!isBelowMD && <Box display={{
            base: "flex"
          }}>
                  <UserAvatar />
                </Box>}
            </>}
        </Flex>
      </HStack>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent backdropFilter="auto" backdropBlur="15px" bgColor={bgColor}>
          <DrawerCloseButton />
          <DrawerHeader>Menü</DrawerHeader>
          <DrawerBody>
            {isEditor(user) && <MobileEditorNav />}
            {!isEditor(user) && <MobileNav />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>;
};
_s2(Navigation, "rG9+vsbSIvsQNLV8Ri4C4sOEA4Q=", false, function () {
  return [useColorMode, useDisclosure, useAuth, useSettings, useColorModeValue, useNavigate, useLocation, useMediaQuery, useMediaQuery, useSearch, useColorModeValue];
});
_c2 = Navigation;
const DesktopNav = () => {
  _s3();
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");
  const [isLargerThan768] = useMediaQuery("(min-width: 992px)");
  const bgColor = useColorModeValue("gray.50", "gray.600");
  const {
    settingByName
  } = useSettings();
  const setting = settingByName("nav-user");
  const settingSmall = settingByName("nav-user-small");
  const [navItems, setNavItems] = useState(setting && setting.data || []);
  useEffect(() => {
    if (isLargerThan768) {
      setNavItems(setting && setting.data || []);
    } else {
      setNavItems(settingSmall && settingSmall.data || []);
    }
  }, [isLargerThan768]);
  return <Stack direction={"row"} spacing={{
    base: "2",
    lg: "4"
  }}>
      {navItems.map((navItem, index) => {
      return <Center key={navItem.label}>
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <Box as="a" p={2} href={navItem.href ?? "#"} fontSize={"md"} fontWeight={300} color={linkColor} _hover={{
              textDecoration: "none",
              color: linkHoverColor
            }}>
                  {navItem.label}
                </Box>
              </PopoverTrigger>

              {navItem.children && <PopoverContent border={0} boxShadow={"xl"} p={4} rounded={"xl"} minW={"sm"} bgColor={bgColor}>
                  <PopoverArrow bgColor={bgColor} />
                  <Stack>
                    {navItem.children.map(child => <DesktopSubNav key={child.label} {...child} />)}
                  </Stack>
                </PopoverContent>}
            </Popover>
          </Center>;
    })}
    </Stack>;
};
_s3(DesktopNav, "v3BuuA84FS/CoUOobuzVecmbFWw=", false, function () {
  return [useColorModeValue, useColorModeValue, useColorModeValue, useMediaQuery, useColorModeValue, useSettings];
});
_c3 = DesktopNav;
const DesktopSubNav = ({
  label,
  href,
  subLabel
}) => {
  _s4();
  return <Box as="a" href={href} role={"group"} display={"block"} p={2} rounded={"md"} _hover={{
    bg: useColorModeValue("pink.50", "gray.900")
  }}>
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text transition={"all .3s ease"} _groupHover={{
          color: "pink.400"
        }} fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex transition={"all .3s ease"} transform={"translateX(-10px)"} opacity={0} _groupHover={{
        opacity: "100%",
        transform: "translateX(0)"
      }} justify={"flex-end"} align={"center"} flex={1}>
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>;
};
_s4(DesktopSubNav, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c4 = DesktopSubNav;
const MobileNav = () => {
  _s5();
  const {
    settingByName
  } = useSettings();
  const setting = settingByName("nav-user");
  const navItems = setting && setting.data || [];
  return <Stack bg={useColorModeValue("white", "gray.800")} p={4} display={{
    md: "none"
  }}>
      {navItems.map(navItem => <MobileNavItem key={navItem.label} {...navItem} />)}
    </Stack>;
};
_s5(MobileNav, "3nQJC0I7dlKc+tU532fFEKPuh3M=", false, function () {
  return [useSettings, useColorModeValue];
});
_c5 = MobileNav;
const MobileEditorNav = () => {
  _s6();
  const {
    settingByName,
    setSettings
  } = useSettings();
  const setting = settingByName("nav-editor");
  const navItems = setting && setting.data || [];
  const fetcher = useFetcher();
  useEffect(() => {
    if (!(setting && setting.data)) {
      fetcher.load("/");
    }
  }, []);
  useEffect(() => {
    if (fetcher.data) {
      const data = fetcher.data;
      data.settings && setSettings(data.settings);
    }
  }, [fetcher.data]);
  return <VStack w={"100%"}>
      <Stack bg={useColorModeValue("whiteAlpha.600", "gray.800")} borderRadius={"lg"} p={4} w={"100%"}>
        {navItems.map(navItem => <MobileNavItem key={navItem.label} {...navItem} />)}
      </Stack>
    </VStack>;
};
_s6(MobileEditorNav, "s1cHX4x2Lc1t4sdBnsD8jYplS/8=", false, function () {
  return [useSettings, useFetcher, useColorModeValue];
});
_c6 = MobileEditorNav;
const MobileNavItem = ({
  label,
  children,
  href,
  open = false
}) => {
  _s7();
  const {
    isOpen,
    onToggle,
    onOpen
  } = useDisclosure();
  useEffect(() => {
    if (open) {
      onOpen();
    }
  }, [open]);
  return <Stack spacing={0}>
      <HStack mb={2} as="a" href={href ?? "#"} justifyContent="space-between" alignItems="center" _hover={{
      textDecoration: "none"
    }}>
        <Text fontWeight={600} color={useColorModeValue("gray.800", "gray.200")} onClick={children && onToggle}>
          {label}
        </Text>
        {children && <Icon as={ChevronDownIcon} transition={"all .25s ease-in-out"} transform={isOpen ? "rotate(180deg)" : ""} w={6} h={6} onClick={children && onToggle} />}
      </HStack>

      <Collapse in={isOpen} animateOpacity style={{
      marginTop: "0!important"
    }}>
        <Stack mt={1} pl={4} mb={3} borderLeft={1} borderStyle={"solid"} borderColor={useColorModeValue("gray.200", "gray.700")} align={"start"}>
          {children && children.map(child => <Box as="a" key={child.label} py={0} href={child.href}>
                {child.label}
              </Box>)}
        </Stack>
      </Collapse>
    </Stack>;
};
_s7(MobileNavItem, "z9+TXWo5oMfZoFlFrePFvP8txqY=", false, function () {
  return [useDisclosure, useColorModeValue, useColorModeValue];
});
_c7 = MobileNavItem;
var _c, _c2, _c3, _c4, _c5, _c6, _c7;
$RefreshReg$(_c, "InputComponent");
$RefreshReg$(_c2, "Navigation");
$RefreshReg$(_c3, "DesktopNav");
$RefreshReg$(_c4, "DesktopSubNav");
$RefreshReg$(_c5, "MobileNav");
$RefreshReg$(_c6, "MobileEditorNav");
$RefreshReg$(_c7, "MobileNavItem");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;