// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/frontpage/Footer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/frontpage/Footer.tsx");
  import.meta.hot.lastModified = "1708335860440.164";
}
// REMIX HMR END

"use client";
import { Box, Container, Stack, SimpleGrid, Text, VisuallyHidden, chakra, useColorModeValue, Link } from "@chakra-ui/react";
import { FaInstagram } from "react-icons/fa";
import { AppStoreBadge, PlayStoreBadge } from "../customIcons";
import { useSettings } from "~/utils/settingUtils";
import { Capacitor } from "@capacitor/core";
const ListHeader = ({
  children
}) => {
  return <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>;
};
_c = ListHeader;
const SocialButton = ({
  children,
  label,
  href
}) => {
  _s();
  return <chakra.button bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")} rounded={"full"} w={8} h={8} cursor={"pointer"} as={"a"} href={href} display={"inline-flex"} alignItems={"center"} justifyContent={"center"} transition={"background 0.3s ease"} _hover={{
    bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200")
  }} target="_blank">
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>;
};
_s(SocialButton, "5rxRexUsrrZIvnjV+vC1pFVSBPE=", false, function () {
  return [useColorModeValue, useColorModeValue];
});
_c2 = SocialButton;
export const Footer = () => {
  _s2();
  const {
    settingByName
  } = useSettings();
  const setting = settingByName("footer");
  const navItems = setting && setting.data || [];
  return <Box bg={useColorModeValue("gray.50", "gray.900")} color={useColorModeValue("gray.700", "gray.200")}>
      <Container as={Stack} maxW={"7xl"} py={10}>
        <SimpleGrid columns={{
        base: 1,
        sm: 2,
        md: 3
      }} spacing={8}>
          {navItems.map((navItem, index) => <Stack align={"flex-start"} key={navItem.label + index}>
              <ListHeader>{navItem.label}</ListHeader>
              {navItem.children && navItem.children.map(navItemChildren => <Box key={navItemChildren.label + index} as="a" href={navItemChildren.href}>
                    {navItemChildren.label}
                  </Box>)}
            </Stack>)}
          {!Capacitor.isNativePlatform() && <Stack align={"flex-start"}>
            <ListHeader>Install App</ListHeader>
            <Link href="https://apps.apple.com/de/app/dealbunny-de/id609526718" target="_blank"><AppStoreBadge /></Link>
            <Link href="https://play.google.com/store/apps/details?id=com.opongo.dealbunny.android&hl=de" target="_blank"><PlayStoreBadge /></Link>
          </Stack>}
        </SimpleGrid>
      </Container>

      <Box borderTopWidth={1} borderStyle={"solid"} borderColor={useColorModeValue("gray.200", "gray.700")}>
        <Container as={Stack} maxW={"6xl"} py={4} direction={{
        base: "column",
        md: "row"
      }} spacing={4} justify={{
        md: "space-between"
      }} align={{
        md: "center"
      }}>
          <Text>© 2022 dealbunny.de GmbH & Co. KG. All rights reserved - v{`${window.ENV.PUBLIC_REACT_APP_VERSION}-${window.ENV.PUBLIC_BUILD_HASH}-${window.ENV.PUBLIC_RUN_NUMBER}`}</Text>
          <Stack direction={"row"} spacing={6}>
            {/* <SocialButton label={"Twitter"} href={"#"}>
              <FaTwitter />
             </SocialButton>
             <SocialButton label={"YouTube"} href={"#"}>
              <FaYoutube />
             </SocialButton> */}
            <SocialButton label={"Instagram"} href={"https://www.instagram.com/dealbunny.de"}>
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>;
};
_s2(Footer, "T6CbAdemRS5JEyIsEnjvsjztO+8=", false, function () {
  return [useSettings, useColorModeValue, useColorModeValue, useColorModeValue];
});
_c3 = Footer;
var _c, _c2, _c3;
$RefreshReg$(_c, "ListHeader");
$RefreshReg$(_c2, "SocialButton");
$RefreshReg$(_c3, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;