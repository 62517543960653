// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/comment/Comments.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$(),
  _s5 = $RefreshSig$(),
  _s6 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/comment/Comments.tsx");
  import.meta.hot.lastModified = "1728030536055.7163";
}
// REMIX HMR END

import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Box, Button, ButtonGroup, Card, CardBody, Code, Editable, EditableTextarea, Flex, HStack, Heading, IconButton, Link, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Spacer, Text, Textarea, VStack, useDisclosure, useEditableControls, useToast } from "@chakra-ui/react";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { BsFlag, BsLink, BsReply, BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import { useAuth } from "~/utils/userUtils";
import { AiOutlineSend } from "react-icons/ai";
import { FETCH_METHODS, fetchFromBackend } from "~/utils/fetchUtils";
import { useFetcher } from "@remix-run/react";
import { affiliLinkUtil } from "~/utils/affiliUtils";
import { FaTrashRestore } from "react-icons/fa";
import { useSettings } from "~/utils/settingUtils";
const CommentMorePopover = props => {
  return <Popover placement="bottom-end" size="sm">
      <PopoverTrigger>
        <IconButton aria-label="Mehr..." icon={<BsThreeDotsVertical />} variant={"ghost"} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">Mehr</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <VStack align={"left"}>
            <Button leftIcon={<BsLink />}>Link zum Kommentar</Button>
            <Button leftIcon={<BsFlag />}>Melden</Button>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>;
};
_c = CommentMorePopover;
// const TextWithLinks: React.FC<Props> = ({ text }) => {
//   const urlPattern = /https?:\/\/(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+)(\/\S*)?/g;

//   const renderText = (text: string) => {
//     const parts = text.split(urlPattern);

//     return parts.map((part, index) => {
//       // Jeder zweite Eintrag ist eine URL
//       if (index % 2 === 1) {
//         return <Link href={part} isExternal color="blue.500">[Link: {part}]</Link>;
//       }
//       return part;
//     });
//   };

//   console.log("text", text)
//   console.log("renderText", renderText(text))
//   return <Box>{renderText(text)}</Box>;
// };
export const TextWithLinks = ({
  text,
  id,
  textOnly = false
}) => {
  _s();
  // Regulärer Ausdruck, der die ganze URL findet
  const urlPattern = /https?:\/\/[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\/\S*)?/g;
  const hasUrls = urlPattern.test(text);

  // Funktion, um den Domainnamen aus der URL zu extrahieren
  const extractDomain = url => {
    const matches = url.match(/https?:\/\/(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+)/);
    return matches ? matches[1] : url;
  };
  async function checkURLAffili(externalLink, event) {
    event.preventDefault();
    await affiliLinkUtil(externalLink).then(result => {
      // console.log(result);
      typeof result == "string" && window.open(result, "_blank");
    });
  }
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const [externLink, setExternLink] = useState();
  const renderText = text => {
    const parts = text.split(urlPattern);
    const urls = text.match(urlPattern) || [];
    return parts.flatMap((part, index) => index < urls.length ? [part, !textOnly ? <Link key={"link-" + id + "-" + index} href={urls[index]} onClick={e => {
      checkURLAffili(urls[index], e).catch(e => {
        console.error(e);
        // if (
        //   confirm(`Dieser Link ist nicht von uns. Trotzdem öffnen?`)
        // ) {
        //   window.open(urls[index], "_blank");
        // }
        setExternLink(urls[index]);
        onOpen();
      });
    }} color="blue.500" cursor="pointer">
                {extractDomain(urls[index])}
              </Link> : <i key={"link-" + id + "-" + index}>
                [{extractDomain(urls[index])}]
              </i>] : [part]);
  };
  const cancelRef = useRef(null);
  return <>
      {textOnly && renderText(text)}
      {!textOnly && <Box>
          {renderText(text)}
          {hasUrls && <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Externer Link öffnen
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Dieser Link ist nicht von uns. Tortzdem öffnen?
                    <Code w={"100%"}>{externLink}</Code>
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Schließen
                    </Button>
                    <Button colorScheme="red" onClick={() => {
                window.open(externLink, "_blank");
              }} ml={3}>
                      Öffnen
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>}
        </Box>}
    </>;
};
_s(TextWithLinks, "I/PEHiAhD5aNEDedKpiZ+EzbL+E=", false, function () {
  return [useDisclosure];
});
_c2 = TextWithLinks;
const EditableComment = props => {
  _s3();
  var _s2 = $RefreshSig$();
  const {
    user,
    login,
    logout
  } = useAuth();
  const {
    comment,
    updateComments
  } = props;
  const [content, setContent] = useState(comment.content);
  const [blocked, setBlocked] = useState(comment.blocked);
  const [enableReply, setEnableReply] = useState(false);
  const [loading, setLoading] = useState(false);
  const contentRef = useRef(null);
  const [editing, setEditing] = useState(false);
  async function updateComment() {
    if (user?.id) {
      const data = {
        content: contentRef.current?.value
      };
      setLoading(true);
      if (user.authToken) {
        await fetchFromBackend({
          method: FETCH_METHODS.UPDATE,
          collectionType: "deal-comments",
          body: {
            data
          },
          id: String(comment.id)
        }).then(async response => {
          setContent(response.data.attributes.content);
          setLoading(false);
        }).catch(e => {
          setLoading(false);
          console.log(e);
        });
      }
    }
  }
  async function removeComment(blocked) {
    if (user?.id) {
      const data = {
        blocked
      };
      setLoading(true);
      if (user.authToken) {
        await fetchFromBackend({
          method: FETCH_METHODS.UPDATE,
          collectionType: "deal-comments",
          body: {
            data
          },
          id: String(comment.id)
        }).then(async response => {
          setBlocked(response.data.attributes.blocked);
          setLoading(false);
        }).catch(e => {
          setLoading(false);
          console.log(e);
        });
      }
    }
  }

  /* Here's a custom control */
  function EditableControls() {
    _s2();
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps
    } = useEditableControls();
    useEffect(() => {
      setEditing(isEditing);
    }, [isEditing]);
    return isEditing ? <ButtonGroup justifyContent="left" size="sm">
        <IconButton aria-label="Check" icon={<CheckIcon />} {...getSubmitButtonProps()} />
        <IconButton aria-label="Close" icon={<CloseIcon />} {...getCancelButtonProps()} />
      </ButtonGroup> : <Flex justifyContent="left">
        <IconButton aria-label="Edit" size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
      </Flex>;
  }

  // useEffect(() => {
  //   console.log("blocked", blocked);
  // }, [blocked]);
  _s2(EditableControls, "TF99glBVOSPoQ8njGwIt8rizQOo=", false, function () {
    return [useEditableControls];
  });
  return <>
      {!blocked && <Editable textAlign="left" defaultValue={content} fontSize="md" isPreviewFocusable={false} onSubmit={() => updateComment()}>
          {!editing && <TextWithLinks text={content} id={comment.id} />}
          <Textarea as={EditableTextarea} ref={contentRef} mb={3} ml={3} />
          <VStack align={"left"}>
            <HStack mt={3}>
              {(user && Number(user.id) == comment.author || user && user.userLevel && user.userLevel >= 2) && <>
                  <EditableControls />
                  <IconButton aria-label="Löschen" icon={<BsTrash />} onClick={() => {
              removeComment(true);
            }} size={"sm"} />
                </>}
              <Button rightIcon={<BsReply />} size={"sm"} onClick={() => setEnableReply(!enableReply)} variant={"ghost"}>
                Antworten
              </Button>
            </HStack>
            {enableReply && user && comment.relatedID && <VStack align={"left"}>
                <Text>Antwort:</Text>
                <CommentWrite comment={comment} dealID={comment.relatedID} updateComments={updateComments} />
              </VStack>}
          </VStack>
        </Editable>}
      {blocked === true && <HStack>
          <Heading size={"sm"} fontStyle={"italic"}>
            Kommentar wurde gelöscht!
          </Heading>
          {(user && Number(user.id) == comment.author || user && user.userLevel && user.userLevel >= 2) && <Button leftIcon={<FaTrashRestore />} size={"sm"} onClick={() => {
        removeComment(false);
      }} variant={"ghost"}>
              Wiederherstellen
            </Button>}
        </HStack>}
    </>;
};
_s3(EditableComment, "o6zCrnIgs1V8vEtFc5aIuOZyhWg=", false, function () {
  return [useAuth];
});
_c3 = EditableComment;
const CommentCards = props => {
  _s4();
  const {
    comments,
    updateComments,
    childLevel = 0
  } = props;
  const {
    user,
    login,
    logout
  } = useAuth();
  let bColor = "transparent";

  // switch (childLevel) {
  //   case 1:
  //     bColor = "gray.400";
  //     break;
  //   case 2:
  //     bColor = "gray.700";
  //      break;
  //   case 2:
  //     bColor = "gray.700";
  //     break;
  //   default:
  //     bColor = "gray.700";
  //     break;
  // }
  // console.log(comments);
  return <VStack w={"100%"}>
      {comments && comments.map(comment => <Card w="100%" key={comment.id} borderRadius={childLevel != 0 ? 0 : "md"} shadow={childLevel != 0 ? 0 : "md"} borderLeft={childLevel != 0 ? "solid 2px" : 0} borderColor={childLevel != 0 ? bColor : "transparent"} id={"comment-" + comment.id}>
            <CardBody pr={childLevel != 0 ? 0 : 5} pb={childLevel != 0 ? 0 : 5}>
              <VStack align={"left"}>
                <HStack>
                  <Avatar name={comment.authorName || undefined} src={comment?.authorAvatarThumb && comment.authorAvatarThumb || comment?.authorAvatar && comment.authorAvatar || undefined} />
                  <VStack align={"left"} gap={0}>
                    <HStack>
                      <Box fontWeight={"bolder"}>{comment.authorName}</Box>
                      {comment?.authorRole && comment?.authorRole == "editor" && <Box fontWeight={"bold"} fontStyle={"italic"} fontSize={"smaller"} color={"gray.500"}>
                          Redakteur
                        </Box>}
                    </HStack>
                    <Text>
                      {moment(comment.createdAt).format("DD.MM.YYYY HH:mm")}
                    </Text>
                  </VStack>
                  <Spacer />
                  <CommentMorePopover comment={comment} />
                </HStack>
                <Box>
                  <EditableComment comment={comment} updateComments={updateComments} />
                </Box>
                {/* <Box>
                  ID: {comment.id} - Parent: {comment.parent} - Children:{" "}
                  {comment.children?.length} - relatedID: {comment.relatedID} - pos: {comment.position}
                 </Box> */}
                {comment.children && <CommentCards comments={comment.children.sort((a, b) => a.id - b.id)} updateComments={updateComments} childLevel={childLevel + 1} />}
              </VStack>
            </CardBody>
          </Card>)}
    </VStack>;
};
_s4(CommentCards, "da/ilpfZPOv7GDaVnKErnIopDAM=", false, function () {
  return [useAuth];
});
_c4 = CommentCards;
const CommentWrite = props => {
  _s5();
  const {
    comment,
    dealID,
    updateComments
  } = props;
  const {
    user,
    login,
    logout,
    checkUsershowLogin
  } = useAuth();
  const contentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const {
    isInBlacklist
  } = useSettings();
  const toast = useToast();
  async function sendComment() {
    if (!contentRef.current?.value || contentRef.current.value.trim() == "") {
      toast({
        title: `Bitte Kommentar eingeben!`,
        variant: "left-accent",
        isClosable: true,
        position: "top-right",
        status: "error"
      });
      return;
    }
    const phraseLC = isInBlacklist(contentRef.current.value);
    if (phraseLC) {
      toast({
        title: `Blacklist: "${phraseLC}" wurde gefunden. Bitte entfernen!`,
        variant: "left-accent",
        isClosable: true,
        position: "top-right",
        status: "error"
      });
      return;
    }
    if (user?.id && user?.authToken) {
      const data = {
        author: user.id,
        content: contentRef.current?.value,
        related: dealID,
        publishedAt: new Date().getTime(),
        commentDate: new Date().getTime(),
        createdAt: new Date().getTime(),
        parent: comment?.id ? comment?.id : null
      };
      setLoading(true);
      await fetchFromBackend({
        method: FETCH_METHODS.CREATE,
        collectionType: "deal-comments",
        body: {
          data
        }
      }).then(async entry => {
        if (entry?.data?.attributes) {
          const attributes = entry.data.attributes;
          const newComment = {
            ...attributes,
            id: entry.data.id,
            parent: comment?.id ? comment?.id : null,
            author: user.id,
            authorName: user.username,
            relatedID: dealID,
            authorAvatar: user.profilePictureThumb
          };
          updateComments(newComment);
          if (contentRef?.current) contentRef.current.value = "";
        }
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        console.log(e);
      });
    }
  }
  return <VStack w={"100%"}>
      <Textarea ref={contentRef} onClick={() => {
      checkUsershowLogin();
    }} colorScheme="pink" placeholder="Erzähl deine Erfahrung" borderColor={"pink.500"} borderWidth={2} />
      {user && <HStack w="100%">
          <Spacer />
          <Button rightIcon={<AiOutlineSend />} onClick={sendComment} loadingText="Sendet..." colorScheme="pink" isLoading={loading} disabled={loading}>
            Senden
          </Button>
        </HStack>}
    </VStack>;
};
_s5(CommentWrite, "MzTRzRmULK2ie8cBV7Bb3AARDPU=", false, function () {
  return [useAuth, useSettings, useToast];
});
_c5 = CommentWrite;
export const Comments = props => {
  _s6();
  const {
    comments,
    deal,
    commentCount = 0
  } = props;
  function filterLatestComments(comments) {
    return comments && comments.filter(comment => moment(comment.createdAt).isAfter(moment(deal.dealDate))) || [];
  }
  const [currentcomments, setCurrentComments] = useState(comments || []);
  const [latestComment, setLatestComment] = useState(filterLatestComments(comments));
  const [nestedComments, setNestedComments] = useState(nestComments(comments));
  const {
    user,
    login,
    logout
  } = useAuth();
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const buttonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [observeButton, setObserveButton] = useState(false);
  const [page, setPage] = useState(1);
  const [showOldComments, setShowOldComments] = useState(false);
  const [commentKey, setCommentKey] = useState("allcomments");
  const fetcher = useFetcher();
  useEffect(() => {
    if (user && isOpen) {
      onClose();
    }
  }, [user]);
  function updateComments(comment) {
    setCurrentComments([comment, ...currentcomments]);
  }
  useEffect(() => {
    const lcomments = filterLatestComments(currentcomments);
    setLatestComment(lcomments);
    if (!showOldComments) {
      const c = nestComments(lcomments);
      setNestedComments(c);
    } else {
      const c = nestComments(currentcomments);
      currentcomments && setNestedComments(c);
    }
  }, [currentcomments, showOldComments]);

  // useEffect(() => {
  //   setCommentKey(`allcomments-${nestedComments.length}`);
  // }, [nestedComments]);

  useEffect(() => {
    if (showOldComments && commentCount > 20 && !observeButton && !loading) {
      const observer = new IntersectionObserver(entries => {
        // Is the button in view?
        if (entries[0].isIntersecting) {
          // setIsButtonVisible(true);
          console.log("Button is visible");

          // You may also want to unobserve after the button is visible
          if (buttonRef.current) {
            console.log("Remove Button observer");
            observer.unobserve(buttonRef.current);
            setObserveButton(false);
          }
          if (page < 3) {
            fetchData();
          }
        }
      }, {
        root: null,
        // relative to the viewport
        threshold: 1.0 // 100% of the button is visible
      });

      setObserveButton(true);
      // console.log("Add Button observer");
      setTimeout(() => {
        buttonRef.current && observer.observe(buttonRef.current);
      }, 1000);

      // Cleanup observer on unmount
      return () => {
        if (buttonRef.current) {
          console.log("Remove Button observer");
          observer.unobserve(buttonRef.current);
          setObserveButton(false);
        }
      };
    }
  }, [loading, showOldComments]);
  useEffect(() => {
    if (fetcher?.data) {
      console.log("fetcher");
      const fetcherData = fetcher.data;
      setCurrentComments([...currentcomments, ...fetcherData.comments]);
      setLoading(false);
    }
  }, [fetcher.data]);
  async function fetchData() {
    if (!loading && commentCount > currentcomments.length) {
      setLoading(true);
      setPage(page + 1);
      deal.id && fetcher.submit({
        page: page,
        dealID: deal.id
      }, {
        method: "post",
        encType: "application/json"
      });
    }
  }
  return <VStack w={"100%"}>
      <CommentWrite dealID={Number(deal.id)} updateComments={updateComments} />
      <CommentCards key={commentKey} w="100%" comments={nestedComments} updateComments={updateComments} />
      {!showOldComments && latestComment.length < commentCount && <Box>
          <Button onClick={() => setShowOldComments(true)}>
            Ältere Kommentare laden
          </Button>
        </Box>}
      {showOldComments && commentCount > 20 && commentCount > currentcomments.length && <Box>
            <Button ref={buttonRef} isLoading={loading} disabled={loading} onClick={() => fetchData()}>
              Weitere Kommentare laden
            </Button>
          </Box>}
    </VStack>;
};
_s6(Comments, "C3yWW14Ft+jPaYe/aGw60aIF9tg=", false, function () {
  return [useAuth, useDisclosure, useFetcher];
});
_c6 = Comments;
function nestComments(currentcomments) {
  if (!currentcomments || !currentcomments.length) {
    return [];
  }
  const commentsMap = {};
  const rootComments = [];

  // initialize map
  currentcomments.forEach(comment => {
    commentsMap[comment.id] = {
      ...comment,
      children: []
    };
  });

  // populate children property and rootComments
  currentcomments.forEach(comment => {
    if (!comment.parent) {
      rootComments.push(commentsMap[comment.id]);
    } else if (comment.parent && String(comment.parent) in commentsMap) {
      commentsMap[comment.parent].children.push(commentsMap[comment.id]);
    }
  });
  return rootComments;
}
var _c, _c2, _c3, _c4, _c5, _c6;
$RefreshReg$(_c, "CommentMorePopover");
$RefreshReg$(_c2, "TextWithLinks");
$RefreshReg$(_c3, "EditableComment");
$RefreshReg$(_c4, "CommentCards");
$RefreshReg$(_c5, "CommentWrite");
$RefreshReg$(_c6, "Comments");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;