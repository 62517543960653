// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/frontpage/Frontpage.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/frontpage/Frontpage.tsx");
}
// REMIX HMR END

"use client";
import { Box, Container, Grid, GridItem, useColorModeValue, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { dealHighlightLinks, links as dealListLinks } from "~/components/dealList";
import { links as loginLinks } from "~/components/login";
import { Footer } from "./Footer";
import { Navbar, Navigation } from "../navigation";
import { Login } from "../login";
import { useEffect, useRef } from "react";
import { Search, SearchContext } from "../search";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useLocation, useNavigate } from "@remix-run/react";
import appStyles from "~/styles/app.css";
import { Capacitor } from "@capacitor/core";
export const links = () => [...dealHighlightLinks(), ...dealListLinks(), ...loginLinks(), {
  rel: "stylesheet",
  href: appStyles
}];
export const SideBarRight = () => {
  return <></>;
};
_c = SideBarRight;
export const useSwipeBack = ({
  onSwipeBack
}) => {
  _s();
  useEffect(() => {
    let startX;
    const onTouchStart = e => {
      startX = e.touches[0].clientX;
      console.log(startX);
    };
    const onTouchMove = e => {
      const touchEndX = e.changedTouches[0].clientX;
      // Trigger onSwipeBack if swipe right is detected
      if (startX + 100 < touchEndX) {
        onSwipeBack();
      }
      console.log(touchEndX);
    };
    document.addEventListener("touchstart", onTouchStart);
    document.addEventListener("touchend", onTouchMove);
    return () => {
      document.removeEventListener("touchstart", onTouchStart);
      document.removeEventListener("touchend", onTouchMove);
    };
  }, [onSwipeBack]);
};
_s(useSwipeBack, "OD7bBpZva5O2jO+Puf00hKivP7c=");
export const Frontpage = props => {
  _s2();
  const {
    showLoginFirst,
    disableNavbar = false,
    isPullable = true
  } = props;
  const {
    onClose,
    onOpen,
    isOpen
  } = useDisclosure();
  const searchRef = useRef();
  const navigate = useNavigate();
  async function handleRefresh() {
    props.handleRefresh ? await props.handleRefresh() : navigate(0);
  }
  const [isBelowMD] = useMediaQuery("(max-width: 767px)");

  // if(Capacitor.getPlatform() == "ios") {
  //   useSwipeBack({
  //     onSwipeBack: () => {
  //       navigate(-1); // Go back in history
  //     },
  //   });
  // }

  const location = useLocation();
  const isAtRoot = location.pathname === "/";
  return <PullToRefresh pullingContent={""} onRefresh={handleRefresh} isPullable={isPullable && isBelowMD && isAtRoot && Capacitor.isNativePlatform()}>

      <Box maxW={{
      base: "100%",
      md: "100%",
      lg: "7xl"
    }} px={{
      base: "0",
      md: "2",
      lg: "4"
    }} mx={"auto"}>

        <Search ref={searchRef} onClose={onClose} onOpen={onOpen} isOpen={isOpen} />

        <SearchContext.Provider value={{
        searchRef,
        onClose,
        onOpen,
        isOpen
      }}>
          <Grid templateAreas={props.sidebar ? {
          base: `"header"
        "main"
        "sidebar"
        "footer"`,
          md: `"header header"
        "main sidebar"
        "footer footer"`
        } : {
          base: `"header"
        "main"
        "footer"`,
          md: `"header header"
        "main main"
        "footer footer"`
        }}

        // gridTemplateRows={"50px 1fr 30px"}
        gridTemplateColumns={{
          base: "auto",
          md: "auto 300px",
          lg: "auto 350px"
        }}
        // w="100%"
        gap={{
          base: "2",
          md: "4"
        }}>

            <GridItem px="0px" area={"header"}>
              {props?.header ? props.header : <Box px="0">
                  <Navigation />
                </Box>}

            </GridItem>
            {props.sidebar && <GridItem px={{
            base: "2",
            md: "4"
          }} mb={4} area={"sidebar"}>
                {props.sidebar}
              </GridItem>}

            <GridItem px="0" area={"main"}>
              {showLoginFirst ? <Login /> : props.main}
            </GridItem>
            <GridItem px="0" area={"footer"}>
              {props?.footer ? props.footer : <Footer />}
            </GridItem>
          </Grid>
          {Capacitor.isNativePlatform() && isBelowMD && <Navbar />}
        </SearchContext.Provider>
      </Box>
    </PullToRefresh>;
};
_s2(Frontpage, "SSQIs/I8oa56bHxYrivaQdsZeDc=", false, function () {
  return [useDisclosure, useNavigate, useMediaQuery, useLocation];
});
_c2 = Frontpage;
export const FrontpageHero = props => {
  _s3();
  const {
    onClose,
    onOpen,
    isOpen
  } = useDisclosure();
  const searchRef = useRef();
  const [isBelowMD] = useMediaQuery("(max-width: 767px)");
  return <>
      <Search ref={searchRef} onClose={onClose} onOpen={onOpen} isOpen={isOpen} />

      <SearchContext.Provider value={{
      searchRef,
      onClose,
      onOpen,
      isOpen
    }}>
        <Container maxW={{
        base: "100%"
      }} px="0px" bgSize={"cover"} bgImg={props.heroImage} minH={{
        base: "250px",
        md: "400px"
      }} pos={"relative"} mb="6">

          <Box style={useColorModeValue({
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(255, 255, 255, 0.5)"
        }, {
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)"
        })}>

            <Container px="0px" maxW={{
            base: "100%",
            md: "7xl"
          }}>
              {props?.header ? props.header : <Navigation />}
            </Container>
          </Box>
        </Container>
        <Container maxW={{
        base: "100%",
        md: "7xl"
      }} px={{
        base: "0",
        md: "4",
        lg: "inherite"
      }}>

          <Grid templateAreas={props.sidebar ? {
          base: `
        "main"
        "sidebar"
        "footer"`,
          md: `
        "main sidebar"
        "footer footer"`
        } : {
          base: `"header"
        "main"
        "footer"`,
          md: `"header header"
        "main main"
        "footer footer"`
        }}

        // gridTemplateRows={"50px 1fr 30px"}
        gridTemplateColumns={{
          base: "100%",
          md: "1fr 320px"
        }} w="100%" gap={{
          base: "2",
          lg: "4"
        }}>

            {props.sidebar && <GridItem pl="0" px={{
            base: "4",
            lg: "0"
          }} area={"sidebar"}>
                {props.sidebar}
              </GridItem>}

            <GridItem pl="0" area={"main"}>
              {props.main}
            </GridItem>
            <GridItem pl="0" area={"footer"}>
              {props?.footer ? props.footer : <Footer />}
            </GridItem>
          </Grid>
          {Capacitor.isNativePlatform() && isBelowMD && <Navbar />}
        </Container>
      </SearchContext.Provider>
    </>;
};
_s3(FrontpageHero, "kxAN1lHiFcQ1cZx7lxkgWQ9C9iU=", false, function () {
  return [useDisclosure, useMediaQuery, useColorModeValue];
});
_c3 = FrontpageHero;
var _c, _c2, _c3;
$RefreshReg$(_c, "SideBarRight");
$RefreshReg$(_c2, "Frontpage");
$RefreshReg$(_c3, "FrontpageHero");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;