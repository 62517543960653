// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/dealList/DealList.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/dealList/DealList.tsx");
  import.meta.hot.lastModified = "1728030536059.7163";
}
// REMIX HMR END

import { Alert, AlertIcon, Box, Button, Center, Divider, Grid, GridItem, HStack, Heading, Image, LinkBox, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Spacer, Stack, Text, VStack, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { Link, useNavigate } from "@remix-run/react";
import { BsChatLeftText } from "react-icons/bs";
import { BlogAuthor, BlogAuthorBig, DealBookmarkButton, DealButton, DealButtonGroup, DealDate, DealHotnessButton, dealButtonLinks } from ".";
import { DealPrice } from "./DealPrice";
import { useEffect, useRef, useState } from "react";
import { shuffleArray } from "~/utils/strapiUtils";
import { sendEvent } from "~/utils/fetchUtils";
import { EditPostButtons } from "../post/PostDashboard";
import { isEditor, useAuth } from "~/utils/userUtils";
import { FaCommentDollar, FaShippingFast } from "react-icons/fa";
import moment from "moment-timezone";
import "moment/locale/de.js";
moment.tz.setDefault("Europe/Berlin");
moment.locale("de");
export const links = () => [...dealButtonLinks()];
export const DealList = props => {
  _s();
  const {
    deals,
    promo,
    showLoading
  } = props;
  // console.log("DealList", props)
  const [hits, setHits] = useState(deals);
  const [promos, setPromos] = useState(promo);
  const [promoHit, setPromoHit] = useState();
  const navigate = useNavigate();
  const [noOfLines, setNoOfLines] = useState(1);
  const {
    user
  } = useAuth();
  const [editor, setEditor] = useState(isEditor(user));
  const [observeButton, setObserveButton] = useState(false);
  const buttonRef = useRef(null);
  useEffect(() => {
    setHits(deals);
    // console.log(deals);
  }, [deals]);
  useEffect(() => {
    setPromoHit(promo && (promo?.length > 1 ? shuffleArray(promo)[0] : promo[0]));
    if (!isOpen) {
      setTimeout(() => {
        onToggle();
      }, 1000);
    }
  }, [promos]);
  const {
    isOpen,
    onToggle
  } = useDisclosure();
  const filterColor = useColorModeValue("brightness(95%) contrast(105%);", "brightness(90%) sepia(10%) contrast(120%);");
  const linkColor = useColorModeValue("gray.700", "gray.200");
  const btnColor = useColorModeValue("gray.600", "gray.100");
  return <>
      <VStack p={{
      base: "0",
      md: "1",
      lg: "5"
    }} mr={{
      base: "0",
      md: "2"
    }}
    // mb={{ base: "3", md: "7" }}
    width={"auto"} overflow={"hidden"}>
        {hits?.map((hit, index) => <Box key={hit.id} w={"100%"}>
            {promoHit && index == (promoHit?.position && promoHit?.position > 1 ? promoHit?.position + 1 : 1) && <Box mb={10}>
                    <Link to={promoHit.targetURL} target="_blank" aria-label={"Werbung: " + promoHit.title}>
                      <Image w={"100%"} h={"100%"} src={promoHit.image} srcSet={promoHit.imageSrcSet} alt={promoHit.title} onClick={() => sendEvent({
              id: promoHit.id,
              event: "banner"
            })} onLoad={() => {
              sendEvent({
                id: promoHit.id,
                event: "banner",
                tag: "view"
              });
            }} />
                    </Link>
                  </Box>}
            <Grid templateAreas={{
          base: `"header header"
          "image main"
          "footer footer"`,
          lg: `"image header"
          "image main"
          "image footer"`
        }} gridTemplateColumns={{
          base: "100px 1fr",
          md: "130px 1fr",
          lg: "200px 1fr"
        }} gap={{
          base: "3",
          md: "5"
        }} w={"100%"}>
              <GridItem pl="1" area={"header"}>
                <HStack display={{
              base: "none",
              md: "flex"
            }}>
                  <DealHotnessButton id={hit.id} hotness={hit.hotness} />
                  <Spacer />
                  <DealDate date={hit.dealDate} />
                </HStack>
                <HStack display={{
              base: "flex",
              md: "none"
            }}>
                  <DealHotnessButton id={hit.id} size="sm" hotness={hit.hotness} />
                  <Spacer />
                  <DealDate date={hit.dealDate} />
                </HStack>
              </GridItem>
              <GridItem pl="1" area={"image"}>
                <Box position="relative" alignItems="center">
                  <Link to={`/deal/${hit.dealURL}`}>
                    <Center width={{
                  base: "100px",
                  md: "130px",
                  lg: "200px"
                }} height={{
                  base: "100px",
                  md: "130px",
                  lg: "200px"
                }} zIndex="2" marginLeft={{
                  base: "0",
                  md: "0"
                }} marginTop="5%" padding={{
                  base: "1",
                  md: "2",
                  lg: "3"
                }} bgColor={"white"} filter={filterColor} borderRadius={"md"} shadow={"md"}>
                      <Image
                  // borderRadius="md"
                  loading="lazy" src={hit.titleImageThumb} srcSet={hit.srcSet && "200" in hit.srcSet && hit.srcSet["200"]} alt={hit.title} objectFit="contain" width={{
                    base: "96px",
                    md: "120px",
                    lg: "190px"
                  }} height={{
                    base: "96px",
                    md: "120px",
                    lg: "190px"
                  }} />
                    </Center>
                  </Link>
                </Box>
              </GridItem>
              <GridItem pl="1" area={"main"}>
                <Heading mb="2" as="h4" size={{
              base: "smaller",
              md: "md",
              lg: "md"
            }} fontWeight={"semibold"}>
                  <Link to={`/deal/${hit.dealURL}`}>
                    <Text color={linkColor} textDecoration="none" _hover={{
                  textDecoration: "none"
                }} noOfLines={{
                  base: 3,
                  md: 3,
                  lg: 4
                }}>
                      {hit.title}
                    </Text>
                  </Link>
                </Heading>
                <Box mb={{
              base: 3
            }}>
                  <Stack direction={{
                base: "column",
                md: "row"
              }}>
                    <DealPrice price={hit.price} originalPrice={hit.originalPrice} discount={hit.discount} />
                    {hit.priceComment && <HStack pl={{
                  base: 1,
                  md: 3
                }}>
                        {hit.priceComment.toLowerCase().indexOf("versand") !== -1 ? <FaShippingFast /> : <FaCommentDollar />}
                        <Text>{hit.priceComment}</Text>
                      </HStack>}
                  </Stack>
                </Box>
                {hit?.voucherCode && <DealButtonGroup id={hit.id || "0"} affiliateLink={hit.affiliateLink} voucherCode={hit.voucherCode} />}
                {hit.expirationDate && moment(hit.expirationDate).isBefore(moment()) && <Alert status="error" mt={5}>
                      <AlertIcon />
                      Deal abgelaufen: {moment(hit.expirationDate).fromNow()}
                    </Alert>}
                {hit.dealInfo && <LinkBox>
                    <Link to="#" onClick={e => {
                e.preventDefault();
                setNoOfLines(0);
              }}>
                      <Alert status="info" mt={5}>
                        <AlertIcon />
                        <Box noOfLines={noOfLines}>{hit.dealInfo}</Box>
                      </Alert>
                    </Link>
                  </LinkBox>}
              </GridItem>
              <GridItem pl="1" area={"footer"}>
                <HStack>
                  <Popover isLazy={true}>
                    <PopoverTrigger>
                      <Button variant="link">
                        <BlogAuthor name={hit.editor_username} imageURL={hit.editor_profilePictureThumb || undefined} />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent m={2}>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <BlogAuthorBig name={hit.editor_username} imageURL={hit.editor_profilePicture || undefined} />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  <Spacer />
                  {editor && <EditPostButtons item={hit} aria-label="Edit Post" size={{
                base: "sm",
                md: "sm",
                lg: "md"
              }} />}
                  <DealBookmarkButton colorScheme="gray" color={btnColor} size={{
                base: "sm",
                md: "sm",
                lg: "md"
              }} id={hit.id} deal={hit} />
                  <Button leftIcon={<BsChatLeftText />} color={btnColor} size={{
                base: "sm",
                md: "sm",
                lg: "md"
              }} onClick={() => {
                navigate("/deal/" + hit.dealURL + "#comments");
              }}>
                    {hit.comments && hit.comments.length || 0}
                  </Button>
                  {!hit.voucherCode && <DealButton affiliateLink={hit.affiliateLink} id={hit.id} />}
                </HStack>
              </GridItem>
            </Grid>
            <Divider mb={{
          base: "3",
          md: "5"
        }} mt={{
          base: "3",
          md: "5"
        }}></Divider>
          </Box>)}
      </VStack>
    </>;
};
_s(DealList, "nluvvIWZXS1XXm2sVwB7nVXY0jA=", false, function () {
  return [useNavigate, useAuth, useDisclosure, useColorModeValue, useColorModeValue, useColorModeValue];
});
_c = DealList;
var _c;
$RefreshReg$(_c, "DealList");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;