// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/navigation/Navbar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/navigation/Navbar.tsx");
  import.meta.hot.lastModified = "1709112347287.6187";
}
// REMIX HMR END

// Navbar.tsx
import React, { useState, useEffect, useRef } from "react";
import { VStack, HStack, Text, useColorMode, useColorModeValue, useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerCloseButton } from "@chakra-ui/react";
import { FaHome, FaSearch, FaComment } from "react-icons/fa";
import { useSearch } from "../search";
import { useNavigate } from "@remix-run/react";
import { UserAvatar } from ".";
import { CommentListSmall } from "../comment/CommentListSmall";
export const Navbar = props => {
  _s();
  const {
    colorMode,
    toggleColorMode
  } = useColorMode();
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [navVisible, setNavVisible] = useState(true);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const bgColor = useColorModeValue("blackAlpha.100", "blackAlpha.600");
  const borderTopColor = useColorModeValue("gray.500", "gray.400");
  const {
    onClose: onCloseSearch,
    onOpen: onOpenSearch,
    isOpen: isOpenSearch
  } = useSearch();
  const navigate = useNavigate();
  const userAvatarRef = useRef();
  const commentsRef = useRef();
  useEffect(() => {
    let isScrolling = null;
    function handleScroll() {
      const st = window.pageYOffset || document.documentElement.scrollTop;

      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(function () {
        // Check the scroll direction
        if (st < lastScrollTop) {
          setNavVisible(true);
        } else if (st > lastScrollTop && st > 50) {
          // Check if the user has scrolled at least 50px for better UX
          setNavVisible(false);
        }
        setLastScrollTop(st <= 0 ? 0 : st); // Reset lastScrollTop to 0 if we're at the top to avoid negative values
      }, 66); // Approximately 15 frames at 60 Hz
    }

    window.addEventListener("scroll", handleScroll, false);
    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [lastScrollTop]);
  useEffect(() => {
    isOpen && commentsRef.current?.forceUpdate && commentsRef.current.forceUpdate();
  }, [isOpen]);
  return <HStack padding="1rem" bgColor={bgColor} borderTop="solid 1px" borderTopColor={borderTopColor} position="fixed" width="100%" bottom="0" transform={navVisible ? "none" : "translateY(100%)"} transition="transform 0.3s ease-in-out" spacing={4} justifyContent="center" backdropFilter="auto" backdropBlur="25px" zIndex={10}>
      <NavButton icon={<FaHome />} label="Deals" onClick={() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      navigate("/");
    }} />
      <NavButton icon={<FaSearch />} label="Suche" onClick={onOpenSearch} />
      <NavButton icon={<FaComment />} label="Kommentare" onClick={() => {
      onOpen();
    }} />
      <NavButton icon={<UserAvatar boxSize={4} ref={userAvatarRef} />} label="Profil" onClick={() => {
      userAvatarRef?.current?.onToggleProfil && userAvatarRef?.current?.onToggleProfil();
    }} />

      <Drawer placement={"bottom"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Neueste Kommentare
          </DrawerHeader>
          <DrawerBody>
            <CommentListSmall ref={commentsRef} chunkSize={20} limit={20} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </HStack>;
};
_s(Navbar, "YLIBTJqdsyyfbi6ZtDLy7f3enUE=", false, function () {
  return [useColorMode, useDisclosure, useColorModeValue, useColorModeValue, useSearch, useNavigate];
});
_c = Navbar;
const NavButton = ({
  icon,
  label,
  onClick
}) => {
  return <VStack align="center" spacing={1} flex="1" onClick={onClick}>
      {icon}
      <Text>{label}</Text>
    </VStack>;
};
_c2 = NavButton;
var _c, _c2;
$RefreshReg$(_c, "Navbar");
$RefreshReg$(_c2, "NavButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;