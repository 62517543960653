// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/post/PostDashboard.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/post/PostDashboard.tsx");
  import.meta.hot.lastModified = "1708335860440.164";
}
// REMIX HMR END

import { EditIcon, SearchIcon } from "@chakra-ui/icons";
import { Avatar, Box, Button, HStack, Heading, IconButton, Image, Input, InputGroup, InputLeftElement, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Switch, Tab, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tag, TagLabel, Tbody, Td, Th, Thead, Tr, VStack, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import { useFetcher, useNavigate } from "@remix-run/react";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { BsClock, BsThreeDotsVertical } from "react-icons/bs";
import { DealDetailPreview } from "../dealDetail";
import { redisScan } from "~/utils/fetchUtils";
import { FaSearch } from "react-icons/fa";
export const PostPreview = props => {
  _s();
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const {
    deal
  } = props;
  const iconButtonProps = {
    ...props
  };
  // delete iconButtonProps.deal;
  const dealHit = deal;
  return <>
      <IconButton onClick={onOpen} {...iconButtonProps} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxH={"90vh"} maxW={380}>
          <ModalHeader>Deal Vorschau</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow={"scroll"} pb={5}>
            <DealDetailPreview deal={dealHit} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>;
};
_s(PostPreview, "b6HgRGUKK6FEfELRcVwOTS4RtgI=", false, function () {
  return [useDisclosure];
});
_c = PostPreview;
export const EditPostButtons = props => {
  _s2();
  const [loading, setLoading] = useState(false);
  const {
    item
  } = props;
  const fetcher = useFetcher();
  function expiredItem(id) {
    setLoading(true);
    id && fetcher.submit({
      data: {
        expirationDate: item.expirationDate ? null : moment().toISOString()
      }
    }, {
      method: "post",
      action: "/api/strapi/update/deals/" + id,
      encType: "application/json"
    });
  }
  const toast = useToast();
  useEffect(() => {
    (async () => {
      if (fetcher.data) {
        setLoading(false);
        toast({
          title: `Deal wurde als abgelaufen markiert!`,
          variant: "left-accent",
          isClosable: true,
          position: "top-right",
          status: "success"
        });
      }
    })();
  }, [fetcher.data]);
  return <Popover placement="bottom-end" size="sm">
      <PopoverTrigger>
        <IconButton icon={<BsThreeDotsVertical />} {...props} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">Mehr</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <VStack align={"left"}>
            <Button leftIcon={<BsClock />} onClick={() => expiredItem(item.id)} isLoading={loading} isDisabled={moment(item.expirationDate).isBefore(moment())}>
              Abgelaufen
            </Button>
            <Button leftIcon={<EditIcon />} onClick={() => {
            window.open(`/posts/edit/${item.id}`, "_blank", "noreferrer");
          }}>
              Editieren
            </Button>
            {/* <Button
              leftIcon={<EditIcon />}
              onClick={() => {
                window.open(`/deal/insta/${item.dealURL}`, "_self", "noreferrer");
              }}
             >
              Instagram
             </Button> */}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>;
};
_s2(EditPostButtons, "g15OhAFYrwK9l0Ve3p7Lvu1E0Sk=", false, function () {
  return [useFetcher, useToast];
});
_c2 = EditPostButtons;
export const PostCard = props => {
  _s3();
  const {
    item
  } = props;
  const editRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [startColor, setStartColor] = useState("gray.200");
  const [stopColor, setStopColor] = useState("gray.200");
  const fetcher = useFetcher();
  const navigate = useNavigate();
  function enableItem(id, publish) {
    setLoading(true);
    id && publish !== undefined && fetcher.submit({
      data: {
        publish
      }
    }, {
      method: "post",
      action: "/api/strapi/update/deals/" + id,
      encType: "application/json"
    });
  }
  useEffect(() => {
    if (fetcher.data) {
      setLoading(false);
    }
  }, [fetcher.data]);
  const startColorMode = useColorModeValue("green.200", "green.600");
  const normalColorMode = useColorModeValue("gray.200", "gray.600");
  const stopColorMode = useColorModeValue("red.200", "red.600");
  useEffect(() => {
    setStartColor(normalColorMode);
    setStopColor(normalColorMode);
    if (moment(item.dealDate).isAfter(moment())) {
      setStartColor(startColorMode);
    }
    if (moment(item.expirationDate).isBefore(moment())) {
      setStopColor(stopColorMode);
    }
  }, [startColorMode, stopColorMode]);
  return <Tr>
      <Td>
        <Box boxSize={{
        base: "60px",
        md: "80px"
      }} borderRadius={"md"} bg={"white"} overflow={"hidden"}>
          <Image src={item?.titleImageThumb || ""} alt={item.title} boxSize={{
          base: "60px",
          md: "80px"
        }} objectFit="contain" loading="lazy" onClick={() => {
          navigate(`/posts/edit/${item.id}`);
          // window.open(`/posts/edit/${item.id}`, "_blank", "noreferrer");
        }} />
        </Box>
      </Td>
      <Td>
        <Switch colorScheme="blue" isChecked={!!item.publish} onChange={e => enableItem(item.id, e.target.checked)} disabled={loading} />
      </Td>
      <Td maxW={"500px"} overflow={"hidden"}>
        <VStack alignItems={"start"} w={"100%"}>
          <Heading size={"sm"} whiteSpace={"normal"}>
            {item.title}
          </Heading>
          {item.editBy && <HStack fontWeight={"bold"}>
              <Box>Wird bearbeitet von:</Box>
              <Tag size="md" colorScheme="red" borderRadius="full" minW={"70px"} maxW={"120px"}>
                <Avatar src={item.editBy.user.profilePictureThumb} size="2xs" name={item.editBy.user.username} ml={-1} mr={2} />
                <TagLabel isTruncated>{item.editBy.user.username}</TagLabel>
              </Tag>
            </HStack>}
          <Box fontWeight={"bold"}>
            Position: {item.position ? item.position : "-"}
          </Box>
          <HStack>
            <Box fontWeight={"bold"}>Datum:</Box>
            <Box bgColor={startColor} padding={1} borderRadius={"md"}>
              {moment(item.dealDate).isAfter(moment()) ? "Geplant" : "Start"}:
              {item.dealDate ? moment(item.dealDate).format("DD.MM.YYYY HH:mm") : "-"}
            </Box>
            {item.expirationDate && <Box bgColor={stopColor} padding={1} borderRadius={"md"}>
                Ende:{" "}
                {item.expirationDate ? moment(item.expirationDate).format("DD.MM.YYYY HH:mm") : "-"}
              </Box>}
          </HStack>
          <HStack w={"100%"}>
            <Box fontWeight={"bold"}>Link:</Box>
            <Box isTruncated>{item.originalLink ? item.originalLink : "-"}</Box>
          </HStack>
        </VStack>
      </Td>
      <Td>
        <HStack>
          <IconButton size={"sm"} icon={<EditIcon />} aria-label="Edit" onClick={() => {
          navigate(`/posts/edit/${item.id}`);
          // window.open(`/posts/edit/${item.id}`, "_blank", "noreferrer");
        }} />
          <PostPreview deal={item} size={"sm"} icon={<SearchIcon />} aria-label="Edit" />
        </HStack>
      </Td>
    </Tr>;
};
_s3(PostCard, "2pp4f1GV/SgYiA6tUop1Ly+YWMk=", false, function () {
  return [useFetcher, useNavigate, useColorModeValue, useColorModeValue, useColorModeValue];
});
_c3 = PostCard;
export const PostDashboard = props => {
  _s4();
  const fetcher = useFetcher();
  const {
    data,
    tab
  } = props;
  const [items, setItems] = useState(data.items);
  const editRef = useRef(null);
  const [query, setQuery] = useState();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(data.meta?.pagination);
  let defaultIndex;
  switch (tab) {
    case "main":
      defaultIndex = 0;
      break;
    default:
      defaultIndex = 0;
      break;
  }
  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const [tabKey, setTabKey] = useState("tab");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log("data", "loaded");
    if (fetcher.state == "idle" && loading) {
      setLoading(false);
      setItems(fetcher.data);
      setTabKey("tab-" + Math.random());
    }
  }, [fetcher]);
  useEffect(() => {
    setItems(data.items);
    redisScan("editby/*").then(results => {
      console.log("Scan editby", results);
      const postItems = items.map(item => {
        const editBy = results.data.find(element => element.postId == item.id);
        return {
          ...item,
          editBy
        };
      });
      setItems(postItems);
    });
  }, []);
  const searchFetcher = useFetcher();
  useEffect(() => {
    if (searchFetcher.data) {
      console.log(searchFetcher.data);
      const itemData = searchFetcher.data;
      if (itemData.meta?.pagination) {
        console.log(itemData.meta?.pagination);
        if (itemData.meta?.pagination.page > 1) {
          setItems([...items, ...itemData.items]);
        } else {
          setItems(itemData.items);
        }
      }
      setPagination(itemData.meta?.pagination);
    }
  }, [searchFetcher.data]);
  function startSearch(searchQuery, current_page = 1) {
    setQuery(searchQuery);
    setPage(current_page);
    searchFetcher.submit({
      query: searchQuery,
      page: current_page
    }, {
      method: "post",
      action: "/posts/dashboard",
      encType: "application/json"
    });
  }
  function loadMore() {
    startSearch(query || "", page + 1);
  }
  const color = useColorModeValue("white", "gray.800");
  const color2 = useColorModeValue("gray.100", "gray.700");
  return <>
      <Tabs key={tabKey} defaultIndex={tabIndex} onChange={index => setTabIndex(index)}>
        <TabList>
          <Tab>
            Deals
            <Tag colorScheme="red" ml={2}>
              {data.meta?.pagination?.total || 0}
            </Tag>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack>
              <HStack w={"100%"} alignItems={"left"}>
                <Link href="/posts/create">
                  <Button>Deal erstellen</Button>
                </Link>
                <InputGroup display={{
                base: "none",
                md: "block"
              }}>
                  <InputLeftElement pointerEvents="none">
                    <FaSearch />
                  </InputLeftElement>
                  <Input placeholder="Deal suchen..." autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck={false} maxLength={512} type="search" minW={{
                  base: "200px",
                  lg: "250px"
                }} bgColor={color} paddingInlineStart={"10"} name="deal_query" id="deal_query" defaultValue={query}
                // onChange={(e) => setQuery(e.target.value)}
                onKeyUp={event => {
                  if (event.key === "Enter") {
                    console.log("Start search");
                    startSearch(event.currentTarget.value.trim());
                  }
                }} />
                </InputGroup>
              </HStack>
              <TableContainer overflow={"scroll"} width={{
              base: "90vw",
              lg: "100%"
            }} bg={color2} mt="4"
            // p="3"
            borderRadius={"xl"}>
                <Table size="sm" variant="striped" colorScheme="teal">
                  <Thead>
                    <Tr>
                      <Th minW={"60px"}></Th>
                      <Th>Online</Th>
                      <Th>Deal</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items && items.map(deal => <PostCard key={deal.id} item={deal} />)}
                  </Tbody>
                  {/* <Tfoot>
                    <Tr>
                      <Th></Th>
                      <Th isNumeric>Online</Th>
                      <Th>Promotion</Th>
                      <Th isNumeric>Pos</Th>
                      <Th isNumeric>Start</Th>
                      <Th isNumeric>Ende</Th>
                      <Th>Link</Th>
                      <Th></Th>
                    </Tr>
                   </Tfoot> */}
                </Table>
              </TableContainer>
              {pagination && pagination.page < pagination.pageCount && <Button onClick={e => {
              loadMore();
            }}>
                  Weitere Deals laden
                </Button>}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>;
};
_s4(PostDashboard, "tHCQG4DPZppCn7ecrPVvD2eIbCU=", false, function () {
  return [useFetcher, useFetcher, useColorModeValue, useColorModeValue];
});
_c4 = PostDashboard;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "PostPreview");
$RefreshReg$(_c2, "EditPostButtons");
$RefreshReg$(_c3, "PostCard");
$RefreshReg$(_c4, "PostDashboard");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;