// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/search/RefinementList.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/search/RefinementList.tsx");
  import.meta.hot.lastModified = "1704193608846.4607";
}
// REMIX HMR END

import { Box, Divider, Heading, Tag, TagLabel, TagLeftIcon, Text } from "@chakra-ui/react";
import { createIcon } from "@chakra-ui/icons";
import { useState } from "react";
function getSVGViewBox(svg) {
  const viewBoxMatch = svg.match(/viewBox="([^"]+)"/);
  const viewBox = viewBoxMatch ? viewBoxMatch[1] : "";
  return viewBox;
}
function getSVGContent(svg) {
  const contentMatch = svg.match(/<path d="([^"]+)"/);
  const content = contentMatch ? contentMatch[1] : "";
  return content;
}
export const RefinementList = props => {
  _s();
  const {
    items,
    title,
    filter,
    updateFilter
  } = props;
  const [tagColors, setTagColors] = useState(() => {
    const initialColors = {};
    items.forEach(item => {
      initialColors[item.id] = filter?.has(item.id) ? "pink" : "gray";
    });
    return initialColors;
  });
  return <>
      <Heading as="h4" size="md" fontSize="md" textStyle="h4" mb="2">
        {title}
      </Heading>
      <Box>
        {items.map(item => {
        return <Tag size={"sm"} key={item.id} maxWidth="200px" variant="subtle" colorScheme={tagColors[item.id]} mr={2} mb={2} onClick={() => {
          !filter?.has(item.id) ? filter?.set(item.id, item) : filter.delete(item.id);
          setTagColors(prev => ({
            ...prev,
            [item.id]: filter?.has(item.id) ? "pink" : "gray"
          }));
          if (updateFilter && filter) {
            updateFilter(filter);
          }

          // console.log(filter);
        }}>
              {item.icon && <TagLeftIcon boxSize="12px" mr={1} as={createIcon({
            displayName: item.label,
            viewBox: getSVGViewBox(item.icon),
            d: getSVGContent(item.icon)
          })} />}
              <TagLabel>{item.label}</TagLabel>
              <Text ml="1">({item.count})</Text>
            </Tag>;
      })}
        <Divider mt={2} mb={3} size="md" />
      </Box>
      {/* <HStack spacing={4} align="stretch">
        <Stack spacing={[1, 1]} direction={["column"]}>
          {items.map((item) => (
            <Checkbox
              type="checkbox"
              checked={item.isRefined}
              // onChange={() => refine(item.value)}
              key={item.label}
              size="md"
              colorScheme="green"
              defaultChecked
            >
              {item.label} ({item.count})
            </Checkbox>
          ))}
        </Stack>
       </HStack> */}
    </>;
};
_s(RefinementList, "Em3A0NflHr03DzHGGqWkqPml3fU=");
_c = RefinementList;
var _c;
$RefreshReg$(_c, "RefinementList");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;