// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/dealList/DealHighlight.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/dealList/DealHighlight.tsx");
}
// REMIX HMR END

import { Box, HStack, Image, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { Link } from "@remix-run/react";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
moment.tz.setDefault("Europe/Berlin");
moment.locale("de");
import styles from "~/styles/dealHighlight.css";
import { multiSearch } from "~/utils/MeiliSearch";
export const dealHighlightLinks = () => [{
  rel: "stylesheet",
  href: styles
}];
export async function getPromos() {
  const now = Math.floor(Date.now() / 1000);
  const filterPromo = `(startTime <= ${now} OR startTime = 0 ) AND (endTime > ${now} OR endTime = 0 ) AND type = "story"`;

  // console.log(filterPromo);

  const promotions = await multiSearch({
    indexUid: "promotion",
    sort: ["position:desc"],
    filter: filterPromo,
    limit: 100
  });
  return promotions;
}
export const DealHighlight = props => {
  _s();
  const [isAtEnd, setIsAtEnd] = useState(false);
  const tabListRef = useRef(null);
  const checkIfScrolledToEnd = () => {
    const {
      scrollWidth,
      clientWidth,
      scrollLeft
    } = tabListRef.current;
    const isScrolledToEnd = scrollLeft + clientWidth >= scrollWidth - 1;
    // console.log("isScrolledToEnd", isScrolledToEnd,scrollLeft+ clientWidth, scrollWidth)
    setIsAtEnd(isScrolledToEnd);
  };
  useEffect(() => {
    const tabList = tabListRef.current;
    tabList.addEventListener("scroll", checkIfScrolledToEnd);
    return () => {
      tabList.removeEventListener("scroll", checkIfScrolledToEnd);
    };
  }, []);
  return <Box pos={"relative"} maxW={"100vw"}>
      <HStack overflow={"scroll"} pt="15px" pb="5px" pl="15px" className="story-box" ref={tabListRef}>

        {props.items && props.items.map((element, index) => {
        // console.log(element.image);
        return <VStack key={index} mr="10px">
                <Link to={element.targetURL}>
                  <Box className="gradient-box story-card-thumb">
                    <Box className="story-card-thumb-inner">
                      <Image loading="lazy" borderRadius="full" boxSize="70px" objectFit="contain" bgColor={"white"} marginTop={"-2px"} src={element.image} srcSet={element.srcSet && "100" in element.srcSet && element.srcSet["100"]} alt={element.title} />

                    </Box>
                  </Box>
                </Link>
                <Text fontSize={"sm"} isTruncated={true} maxW={75}>
                  {element.title}
                </Text>
              </VStack>;
      })}
      </HStack>
      {!isAtEnd && <Box w={"10px"} height={"100%"} pos={"absolute"} right={0} top={0} bottom={0} bgGradient={useColorModeValue("linear(to right, transparent, blackAlpha.200)", "linear(to right, transparent, gray.800)")}>
      </Box>}
    </Box>;
};
_s(DealHighlight, "DMpQCiDVYVT41/EDUcBT9igMbQI=", false, function () {
  return [useColorModeValue];
});
_c = DealHighlight;
var _c;
$RefreshReg$(_c, "DealHighlight");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;