// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/comment/CommentListSmall.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/comment/CommentListSmall.tsx");
  import.meta.hot.lastModified = "1728030536055.7163";
}
// REMIX HMR END

import { Avatar, Box, Center, Container, Divider, Flex, HStack, Heading, Image, SkeletonText, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack, Wrap, WrapItem, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import { Link } from "@remix-run/react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { BsDot } from "react-icons/bs";
import { TimeIcon } from "@chakra-ui/icons";
import moment from "moment-timezone";
moment.tz.setDefault("Europe/Berlin");
moment.locale("de");
import { TextWithLinks } from ".";
import { useAuth } from "~/utils/userUtils";
function chunkArray(array, chunkSize) {
  let results = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    results.push(array.slice(i, i + chunkSize));
  }

  // console.log(results);
  return results;
}
export const CommentDate = props => {
  _s();
  const dealDate = props.date ? moment(props.date) : moment();
  const threeDaysAgo = moment().subtract(1, "days");
  const [dateString, setDateString] = useState(dealDate.format("DD.MM.yyyy HH:mm"));
  useEffect(() => {
    if (!dealDate.isBefore(threeDaysAgo)) {
      const dateStr = dealDate.fromNow();
      setDateString(dateStr);
    }
  }, []);
  return <HStack fontWeight={"semibold"} fontSize={"sm"} {...props}>
      <TimeIcon />
      <Text>{dateString}</Text>
    </HStack>;
};
_s(CommentDate, "6hh6/2i4IezPhe6IyXt+Inp0VTM=");
_c = CommentDate;
export const CommentListSmall = _s2(forwardRef(_c2 = _s2((props, ref) => {
  _s2();
  const {
    comments,
    title,
    filter,
    updateFilter,
    chunkSize = 5,
    limit = 10
  } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  // const [hits, setHits] = useState<CommentHit[]>([]);
  const [chunks, setChunks] = useState([]);
  const [initFetched, setInitFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 992px)");
  const {
    user,
    login,
    logout
  } = useAuth();
  const loadCommentList = async value => {
    setLoading(true);
    await fetch(`/api/comments/24/${limit}`, {
      headers: {
        "Content-Type": "application/json" // Add this line
      }
    }).then(res => res.json()).then(data => {
      if (data.commentHits && data.commentHits.length > 0) {
        setChunks(data.commentHits ? chunkArray(data.commentHits, chunkSize) : []);
      }
    }).catch(console.error);
    setLoading(false);
  };
  useEffect(() => {
    if (comments && comments.length > 0) {
      setChunks(comments ? chunkArray(comments, chunkSize) : []);
    }
  }, [comments]);
  useEffect(() => {
    if (!initFetched && isLargerThan768) {
      setInitFetched(true);
      loadCommentList(24);
    }
  }, [isLargerThan768]);
  useEffect(() => {
    if (!initFetched) {
      setInitFetched(true);
      loadCommentList(24);
    }
  }, []);
  useImperativeHandle(ref, () => ({
    forceUpdate: () => {
      loadCommentList(24);
    }
  }));
  const color = useColorModeValue("blue.100", "gray.500");
  const _selected = useColorModeValue("blue.500", "gray.100");
  return <>
      <Container p="4" backgroundColor={useColorModeValue("gray.100", "gray.700")} borderRadius="xl">
        <Box>
          <Text fontSize="lg">{title}</Text>
        </Box>
        <Tabs variant="unstyled" align="center">
          <TabPanels textAlign={"left"}>
            {chunks.length == 0 && [0, 1, 2, 3, 4].map(n => <Box key={"skel-" + n} padding="2">
                  <SkeletonText mt="4" noOfLines={4} spacing="2" skeletonHeight="2" />
                </Box>)}
            {chunks.length > 0 && chunks.map((hits, index) => <TabPanel key={"tab-" + index} p={0}>
                  <VStack>
                    {loading && <Spinner size="md" mb={5} />}
                    {hits.map(hit => <Box key={hit.id} w={"100%"}>
                        <Flex marginTop={{
                  base: "1",
                  sm: "1"
                }} w={"100%"} marginBottom={3}>
                          <Link to={`/deal/${hit.relatedURL}#comments`}>
                            <Box marginRight="3" position="relative" alignItems="center">
                              <Center width={{
                        base: "65px",
                        sm: "70px"
                      }} height={{
                        base: "65px",
                        sm: "70px"
                      }} zIndex="2" marginLeft={{
                        base: "0",
                        sm: "5%"
                      }} marginTop="2" padding={1} bgColor={"white"} borderRadius={"3"}>
                                <Center textDecoration="none" _hover={{
                          textDecoration: "none"
                        }} height="100%">
                                  <Image
                          // borderRadius="md"
                          loading="lazy" src={hit.relatedImage || hit.relatedImage}
                          // srcSet={hit.srcSet}
                          alt={hit.title} objectFit="contain" width="100%" maxH="100%" />
                                </Center>
                              </Center>
                            </Box>
                          </Link>
                          <Link to={`/deal/${hit.relatedURL}#comments`}>
                            <VStack alignItems={"left"} marginTop={{
                      base: "3",
                      sm: "0"
                    }} pl={1} gap={1}>
                              <Wrap>
                                <WrapItem>
                                  <Avatar size={"xs"} name={hit.authorName || undefined} src={hit?.authorAvatarThumb && hit.authorAvatarThumb || hit?.authorAvatar && hit.authorAvatar || undefined} />
                                </WrapItem>
                                <WrapItem>
                                  <Heading marginTop="1" mb={1} as="h5" size="xs">
                                    {hit.authorName}
                                  </Heading>
                                </WrapItem>
                                {hit?.authorRole && hit?.authorRole == "editor" && <WrapItem>
                                      <Box fontWeight={"bold"} fontStyle={"italic"} fontSize={"smaller"} color={"gray.500"} height={"100%"} alignContent={"center"}>
                                        Redakteur
                                      </Box>
                                    </WrapItem>}
                              </Wrap>
                              {hit.createdAt && <CommentDate ml={2} date={hit.createdAt} />}
                              <Text noOfLines={{
                        base: 4,
                        md: 4
                      }} textDecoration="none" _hover={{
                        textDecoration: "none"
                      }} ml={2} maxW={"100%"} wordBreak={"break-word"}>
                                <TextWithLinks text={hit.content} id={hit.id} textOnly={true} />
                              </Text>
                            </VStack>
                          </Link>
                        </Flex>
                        <Divider></Divider>
                      </Box>)}
                  </VStack>
                </TabPanel>)}
          </TabPanels>
          <TabList>
            {chunks.map((hits, index) => <Tab key={index} p="0" fontSize={"xl"} color={color} _selected={{
            color: _selected
          }} aria-label={"Scroll Tab " + index}>
                <BsDot />
              </Tab>)}
          </TabList>
        </Tabs>
      </Container>
    </>;
}, "jO3BvO2Bzu4hOj52js8HeamOxp8=", false, function () {
  return [useMediaQuery, useAuth, useColorModeValue, useColorModeValue, useColorModeValue];
})), "jO3BvO2Bzu4hOj52js8HeamOxp8=", false, function () {
  return [useMediaQuery, useAuth, useColorModeValue, useColorModeValue, useColorModeValue];
});
_c3 = CommentListSmall;
var _c, _c2, _c3;
$RefreshReg$(_c, "CommentDate");
$RefreshReg$(_c2, "CommentListSmall$forwardRef");
$RefreshReg$(_c3, "CommentListSmall");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;