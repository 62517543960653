// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/search/Instantsearch.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$(),
  _s5 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/search/Instantsearch.tsx");
  import.meta.hot.lastModified = "1708510956415.2344";
}
// REMIX HMR END

import { Box, Button, Collapse, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, FormControl, HStack, Heading, Icon, Input, InputGroup, InputLeftElement, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Spacer, Tag, TagLabel, TagLeftIcon, Text, VStack, useColorModeValue, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { createContext, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { getCategories, multiSearch, parseFacetDistribution, parseHitToDealHit } from "~/utils/MeiliSearch";
import { RefinementList } from ".";
import { SearchListSmall } from "./SearchListSmall";
import { DragHandleIcon, Search2Icon } from "@chakra-ui/icons";
import { BsAlarm } from "react-icons/bs";
import { FETCH_METHODS, fetchFromBackend, sendSearch } from "~/utils/fetchUtils";
import { Capacitor } from "@capacitor/core";
import { useAuth } from "~/utils/userUtils";
import moment from "moment-timezone";
import { useFetcher } from "@remix-run/react";
import { LoginPopup } from "../login";
import { useSettings } from "~/utils/settingUtils";
class PromiseQueue {
  constructor(concurrency = 1) {
    this.concurrency = concurrency;
    this.queue = [];
    this.activeCount = 0;
  }
  enqueue(promiseFn) {
    return new Promise((resolve, reject) => {
      const task = () => {
        this.activeCount++;
        promiseFn().then(resolve).catch(reject).finally(() => {
          this.activeCount--;
          if (this.queue.length) {
            const nextTask = this.queue.shift();
            if (nextTask) {
              nextTask();
            }
          }
        });
      };
      if (this.activeCount < this.concurrency) {
        task();
      } else {
        this.queue.push(task);
      }
    });
  }
}
export const SearchFilter = _s(forwardRef(_c = _s((props, ref) => {
  _s();
  const {
    inputValue,
    setInputValue,
    setDeals
  } = props;
  const {
    isOpen,
    onOpen,
    onClose,
    onToggle
  } = useDisclosure();
  const [showDealsIfEmpty, setShowDealsIfEmpty] = useState(true);
  const [maxPrice, setMaxPrice] = useState(12000);
  const [minPrice, setMinPrice] = useState(0);
  const [newMaxPrice, setNewMaxPrice] = useState(12000);
  const [newMinPrice, setNewMinPrice] = useState(0);
  const [defaultMaxPrice, setDefaultMaxPrice] = useState(12000);
  const [defaultMinPrice, setDefaultMinPrice] = useState(0);
  const [refinementFilter, setRefinementFilter] = useState(new Map());
  const queue = new PromiseQueue(1);
  const [refinementListProducts, setRefinementListProducts] = useState([]);
  const [refinementListCategories, setRefinementListCategories] = useState([]);
  const [refinementListBrands, setRefinementListBrands] = useState([]);
  const [refinementListShops, setRefinementListShops] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [componentKey, setComponentKey] = useState(0);
  const {
    isInBlacklist
  } = useSettings();
  const [lastQuery, setLastQuery] = useState();
  function setQuery(newQuery, saveSearch = false) {
    if (newQuery == lastQuery) return;
    setLastQuery(newQuery);
    setInputValue(newQuery);
    const phraseLC = isInBlacklist(newQuery);
    function isLatinText(input) {
      if (input == "") {
        return true;
      }
      const regex = /^[A-Za-z0-9 .\-#+ÄäÖöÜüß!@?*]+$/;
      return regex.test(input);
    }
    if (saveSearch && !phraseLC && isLatinText(newQuery) && newQuery.length > 2) sendSearch({
      event: "search",
      query: newQuery.trim(),
      value: 1
    });
    queue.enqueue(() => updateDeals(newQuery, allCategories).then(result => setComponentKey(prevKey => prevKey + 1)));
  }
  function onToggleFilter() {
    onToggle();
  }
  useImperativeHandle(ref, () => ({
    resetFilter: () => {
      setQuery("");
      setRefinementFilter(new Map());
    },
    setQuery,
    onOpenSearch,
    onToggleFilter
  }));
  useEffect(() => {
    if (isOpen) {
      queue.enqueue(() => updateDeals(inputValue, allCategories).then(result => {
        setNewMinPrice(minPrice);
        setNewMaxPrice(maxPrice);
        setDefaultMinPrice(minPrice);
        setDefaultMaxPrice(maxPrice);
        setComponentKey(prevKey => prevKey + 1);
      }));
    }
  }, [refinementFilter]); // `count` is a dependency

  useEffect(() => {
    if (isOpen) {
      setNewMinPrice(minPrice);
      setNewMaxPrice(maxPrice);
      setDefaultMinPrice(minPrice);
      setDefaultMaxPrice(maxPrice);
      setComponentKey(prevKey => prevKey + 1);
    }
  }, [minPrice, maxPrice]); // `count` is a dependency

  useEffect(() => {
    onOpenSearch();
  }, []); // `count` is a dependency

  async function updateDeals(q, categories, searchFilter) {
    const facets = ["categories.id", "brands.id", "products.id", "shops.id", "price"];
    let mFilter = searchFilter;
    const filterString = refinementFilter.size > 0 ? generateFilterFromMap(refinementFilter) : "";
    if (filterString) {
      if (searchFilter) {
        mFilter = searchFilter + ` AND ${filterString}`;
      } else {
        mFilter = filterString;
      }
    }
    const results = await multiSearch({
      query: q,
      facets: facets,
      filter: mFilter,
      attributesToHighlight: ["title", "categories.title", "brands.title", "products.title", "shops.title"],
      attributesToSearchOn: ["title", "categories.title", "brands.title", "products.title", "shops.title"],
      attributesToRetrieve: ["title", "categories", "brands", "products", "shops", "dealDate", "expirationDate", "dealURL", "affiliateLink", "titleImage", "titleImageThumb", "price", "originalPrice", "discount", "editor_username", "editor_profilePicture", "editor_profilePictureThumb", "srcSet", "publish", "id"]
    });
    const hits = results[0].hits.map(hit => parseHitToDealHit(hit));
    let min = results[0]?.facetStats?.price?.min ? results[0].facetStats.price.min : 0;
    let max = results[0]?.facetStats?.price?.max ? results[0].facetStats.price.max : 100000;
    if (!searchFilter || showDealsIfEmpty) {
      setMinPrice(Math.floor(min));
      setMaxPrice(Math.ceil(max));
    }
    min = min > newMinPrice ? min : newMinPrice;
    max = max < newMaxPrice ? max : newMaxPrice;
    setDefaultMinPrice(Math.floor(min));
    setDefaultMaxPrice(Math.ceil(max));
    let items = parseFacetDistribution(results[0].facetDistribution, "categories.id", 5, categories);
    setRefinementListCategories(items);
    items = parseFacetDistribution(results[0].facetDistribution, "brands.id", 5, categories);
    setRefinementListBrands(items);
    items = parseFacetDistribution(results[0].facetDistribution, "products.id", 5, categories);
    setRefinementListProducts(items);
    items = parseFacetDistribution(results[0].facetDistribution, "shops.id", 5, categories);
    setRefinementListShops(items);
    setDeals(hits);
    return hits;
  }
  async function fetchAllCategories() {
    const categories = await getCategories("");
    return categories;
  }
  async function onOpenSearch() {
    if (showDealsIfEmpty) {
      await fetchAllCategories().then(result => {
        // console.log(result);
        setAllCategories(result);
        queue.enqueue(() => updateDeals("", result).then(value => setShowDealsIfEmpty(false)));
      });
    }
  }
  function generateFilterFromMap(filterMap) {
    const ids = [...filterMap.keys()];
    return `(categories.id IN [${ids.join(", ")}] OR brands.id IN [${ids.join(", ")}] OR products.id IN [${ids.join(", ")}] OR shops.id IN [${ids.join(", ")}])`;
  }
  const updateDealsWithFilter = () => {
    queue.enqueue(() => updateDeals(inputValue, allCategories).then(result => {
      setComponentKey(prevKey => prevKey + 1);
    }));
  };
  return <>
      <Collapse in={isOpen} animateOpacity>
        <HStack mt={10}>
          <Heading as="h4" size="md" fontSize="md" textStyle="h4" mb="2">
            Preis{" "}
            {newMinPrice > defaultMinPrice ? newMinPrice : defaultMinPrice}€ -{" "}
            {newMaxPrice < defaultMaxPrice ? newMaxPrice : defaultMaxPrice}€{" "}
          </Heading>
          <Spacer />
          <Button mb="2" colorScheme="gray" size={"sm"} onClick={async v => {
          setNewMinPrice(minPrice);
          setNewMaxPrice(maxPrice);
          setDefaultMinPrice(minPrice);
          setDefaultMaxPrice(maxPrice);
          setRefinementFilter(new Map());
        }}>
            Reset
          </Button>
        </HStack>
        <Box mx={5}>
          <RangeSlider key={componentKey} defaultValue={[defaultMinPrice, defaultMaxPrice]} min={minPrice} max={maxPrice} aria-label={["min", "max"]} step={1} mb="2" onChange={val => {
          setNewMinPrice(val[0]);
          setNewMaxPrice(val[1]);
        }} onChangeEnd={val => {
          // console.log("onChangeEnd", val[0], val[1]);
          setNewMinPrice(val[0]);
          setNewMaxPrice(val[1]);

          // setTimeout(() => {
          queue.enqueue(() => updateDeals(inputValue, allCategories, `price >= ${newMinPrice} AND price <= ${newMaxPrice}`));
          // }, 200);
        }}>
            <RangeSliderTrack bg="pink.100">
              <RangeSliderFilledTrack bg="pink.500" />
            </RangeSliderTrack>
            <RangeSliderThumb boxSize={6} index={0} />
            <RangeSliderThumb boxSize={6} index={1} />
          </RangeSlider>
        </Box>

        <Divider mb="4" />
        <RefinementList items={refinementListCategories} title="Kategorie" filter={refinementFilter} key={"list_cat_" + componentKey} updateFilter={updateDealsWithFilter} />
        {refinementListBrands.length > 0 && <RefinementList items={refinementListBrands} title="Marke" filter={refinementFilter} key={"list_brand_" + componentKey} updateFilter={updateDealsWithFilter} />}
        {refinementListProducts.length > 0 && <RefinementList items={refinementListProducts} title="Produkt" filter={refinementFilter} key={"list_product_" + componentKey} updateFilter={updateDealsWithFilter} />}
        {refinementListShops.length > 0 && <RefinementList items={refinementListShops} title="Shops" filter={refinementFilter} key={"list_shop_" + componentKey} updateFilter={updateDealsWithFilter} />}
      </Collapse>
    </>;
}, "DmfAV0gmAv5j5xIungCMqwirlsI=", false, function () {
  return [useDisclosure, useSettings];
})), "DmfAV0gmAv5j5xIungCMqwirlsI=", false, function () {
  return [useDisclosure, useSettings];
});
_c2 = SearchFilter;
export const SearchContext = createContext({
  searchRef: null,
  onOpen: () => {},
  onClose: () => {},
  isOpen: false
});
export const useSearch = () => {
  _s2();
  const {
    searchRef,
    onClose,
    onOpen,
    isOpen
  } = useContext(SearchContext);
  return {
    searchRef,
    onClose,
    onOpen,
    isOpen
  };
};
_s2(useSearch, "Lpt6MiQ5Eq5fmJPHiysJscFeheA=");
export const SearchTerm = props => {
  _s3();
  const {
    value,
    onSearch,
    hidden
  } = props;
  const [searchTerms, setSearchTerms] = useState(null);
  const [showSearchTerm, setShowSearchTerm] = useState(!value);
  async function getSearchTerm(term) {
    const results = await multiSearch({
      query: term,
      indexUid: "search",
      filter: "push IS NULL",
      sort: ["count:desc"],
      limit: 5
    });
    if (results.length > 0) {
      const hits = results[0].hits;
      setSearchTerms(hits);
      const index = hits.findIndex(hit => hit.term == value);
      // console.log(index);
      setShowSearchTerm(index == -1);
    }

    // setHideSearchResults(false);
  }

  useEffect(() => {
    getSearchTerm(value);
  }, [value]); // `count` is a dependency

  return <Box bg={useColorModeValue("gray.100", "gray.500")} borderBottomRadius={"2xl"} borderTopRadius={"none"} borderTop={0} p={2} hidden={hidden}>
      <VStack alignItems={"right"}>
        {showSearchTerm && value != "" && <Tag size={"md"} variant="subtle" colorScheme="cyan" onClick={e => {
        onSearch(value);
      }}>
            <TagLeftIcon boxSize="12px" as={Search2Icon} />
            <TagLabel>{value}</TagLabel>
          </Tag>}
        {searchTerms?.map((item, index) => <Tag size={"md"} key={"searchterm-" + index} variant="subtle" colorScheme="blue" onClick={e => {
        onSearch(item.term);
        // setHideSearchResults(true);
      }}>
            <TagLeftIcon boxSize="12px" as={Search2Icon} />
            <TagLabel>{item.term}</TagLabel>
          </Tag>)}
      </VStack>
    </Box>;
};
_s3(SearchTerm, "2Wn0cTyWAbWQm68gaOGE5rJDGoY=", false, function () {
  return [useColorModeValue];
});
_c3 = SearchTerm;
export const DealAlarm = props => {
  _s4();
  const {
    value
  } = props;
  const {
    user,
    login
  } = useAuth();
  const [search, setSearch] = useState(null);
  const [hasPush, setHasPush] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const fetcherEventLogin = useFetcher();
  useEffect(() => {
    if (user) {
      const push = getPush();
      push && setHasPush(true);
    }
  }, []);
  useEffect(() => {
    if (fetcherEventLogin.data) {
      const data = fetcherEventLogin.data;
      if (data.id) {
        login(data);
      }
    }
  }, [fetcherEventLogin.data]);
  function getPush() {
    let push = null;
    if (Capacitor.isNativePlatform()) {
      const token = localStorage && localStorage.getItem("pushToken");
      if (token) {
        push = user?.pushs?.find(item => token.startsWith(item.pushToken));
      }
    } else {
      push = user?.pushs?.find(item => item.type == "email");
    }
    return push;
  }
  async function getSearches() {
    const push = getPush();
    if (push) {
      const searches = await fetchFromBackend({
        method: FETCH_METHODS.ENTRIES,
        collectionType: "searches",
        params: {
          filters: {
            push: {
              id: {
                $eq: push.id
              }
            }
          }
        }
      }).then(response => {
        console.log(response);
        return response.data.map(item => ({
          id: item.id,
          ...item.attributes
        }));
      });
      return searches;
    } else {
      return null;
    }
  }
  useEffect(() => {
    if (value) {
      getSearches().then(searches => {
        if (searches) {
          const term = searches.find(item => item.term == value);
          if (term) {
            setSearch(term);
          } else {
            setSearch(null);
          }
        }
      });
    }
  }, [value]);
  function enableDealAlarm() {
    if (search) {
      fetchFromBackend({
        method: FETCH_METHODS.DELETE,
        collectionType: "searches",
        id: search.id
      }).then(response => {
        console.log(response);
        if (!response.error) {
          setSearch(null);
          fetcherEventLogin.load("/user");
        }
      });
    } else {
      const push = getPush();
      if (push) {
        fetchFromBackend({
          method: FETCH_METHODS.CREATE,
          collectionType: "searches",
          body: {
            data: {
              term: value,
              user: user?.id,
              revokeDate: moment().add(6, "M").toISOString(),
              push: push.id
            }
          }
        }).then(response => {
          setSearch(response.data);
          fetcherEventLogin.load("/user");
          console.log(response);
        });
      } else {
        if (!Capacitor.isNativePlatform()) {
          fetchFromBackend({
            method: FETCH_METHODS.CREATE,
            collectionType: "pushs",
            body: {
              data: {
                deviceName: "Newsletter",
                type: "email",
                pushToken: user?.email,
                enable: true,
                muteStart: "20:00:00",
                muteEnd: "08:00:00"
              }
            }
          }).then(response => {
            if (response.data) {
              fetchFromBackend({
                method: FETCH_METHODS.CREATE,
                collectionType: "searches",
                body: {
                  data: {
                    term: value,
                    user: user?.id,
                    revokeDate: moment().add(6, "M").toISOString(),
                    push: response.data.id
                  }
                }
              }).then(response => {
                setSearch({
                  id: response.data.id,
                  ...response.data.attributes
                });
                console.log(response);
                fetcherEventLogin.load("/user");
              });
            }
          });
        }
      }
    }
  }
  return <Box>
      {value.length >= 2 && <Button leftIcon={<BsAlarm />} onClick={() => {
      user ? enableDealAlarm() : setShowLogin(true);
    }} colorScheme={search ? "red" : "gray"}>
          Deal Alarm
        </Button>}
      {showLogin && <LoginPopup text="Melde dich an, damit du den Deal Alarm aktivieren kannst." />}
    </Box>;
};
_s4(DealAlarm, "mvseYaRj/laI0KbgEI2zDui9Qko=", false, function () {
  return [useAuth, useFetcher];
});
_c4 = DealAlarm;
export const Search = _s5(forwardRef(_c5 = _s5((props, ref) => {
  _s5();
  const {
    onOpen,
    onClose,
    isOpen
  } = props;
  const [isBelowMD] = useMediaQuery("(max-width: 767px)");
  const firstField = useRef(null);
  const [inputValue, setInputValue] = useState(props.query || "");
  const [deals, setDeals] = useState([]);
  const childRef = useRef();
  const [hideSearchTerms, setHideSearchTerms] = useState(false);
  function startSearch(term, saveSearch = false) {
    term.length < 64 && childRef.current && childRef.current.setQuery && childRef.current.setQuery(term, saveSearch);
    // setHideSearchTerms(true);
  }

  function onToggle() {
    childRef.current?.onToggleFilter && childRef.current?.onToggleFilter();
  }
  const listRef = useRef(null);
  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTop = 0; // Scrolls to the top of the list
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [deals]);
  return <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} initialFocusRef={firstField} size={"sm"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <VStack alignItems={"left"} gap={0}>
              <Text fontSize="2xl">Dealsuche</Text>
              <FormControl role="search" onSubmit={event => {
              event.preventDefault();
              event.stopPropagation();
              if (firstField.current) {
                firstField.current.blur();
              }
            }} onReset={event => {
              event.preventDefault();
              event.stopPropagation();
              if (childRef.current && childRef.current.resetFilter) {
                childRef.current.resetFilter();
              }

              // setQuery("");
              // setRefinementFilter(new Map());

              if (firstField.current) {
                firstField.current.focus();
              }
            }}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<Icon as={FaSearch} />} />
                  <Input placeholder="Deal suchen..." ref={firstField} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck={false} maxLength={512} type="search" borderTopRadius={"2xl"} borderBottomRadius={hideSearchTerms ? "2xl" : "none"} borderBottomWidth={hideSearchTerms ? 1 : 0} value={inputValue ? inputValue : ""} bgColor={useColorModeValue("gray.100", "gray.500")} onChange={event => {
                  setInputValue(event.currentTarget.value);
                  startSearch(event.currentTarget.value.trim());
                  setHideSearchTerms(false);
                }} onKeyUp={event => {
                  if (event.key === "Enter") {
                    console.log("Enter key pressed!");
                    startSearch(event.currentTarget.value.trim(), true);
                    setHideSearchTerms(true);
                    firstField.current && firstField.current.blur();
                  }
                }} onFocus={e => {
                  setHideSearchTerms(false);
                }}
                // onBlur={(e) => {
                //   setHideSearchTerms(true);
                // }}
                id="deal_suchen_drawer" name="deal_suchen_drawer" />
                </InputGroup>
              </FormControl>
              <SearchTerm value={inputValue} onSearch={term => {
              startSearch(term, true);
              setHideSearchTerms(true);
            }} hidden={hideSearchTerms} />
            </VStack>
          </DrawerHeader>

          <DrawerBody ref={listRef}>
            <HStack>
              <Button onClick={onToggle} leftIcon={<DragHandleIcon />}>
                Filter
              </Button>
              {!!inputValue && <DealAlarm value={inputValue} />}
            </HStack>

            <SearchFilter ref={childRef} isOpenDrawer={isOpen} query={inputValue} setDeals={setDeals} setInputValue={setInputValue} inputValue={inputValue} />
            <SearchListSmall hits={inputValue.length ? deals : []} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>;
}, "5nUWCsI15L04klMPnDm3h13po4Y=", false, function () {
  return [useMediaQuery, useColorModeValue];
})), "5nUWCsI15L04klMPnDm3h13po4Y=", false, function () {
  return [useMediaQuery, useColorModeValue];
});
_c6 = Search;
var _c, _c2, _c3, _c4, _c5, _c6;
$RefreshReg$(_c, "SearchFilter$forwardRef");
$RefreshReg$(_c2, "SearchFilter");
$RefreshReg$(_c3, "SearchTerm");
$RefreshReg$(_c4, "DealAlarm");
$RefreshReg$(_c5, "Search$forwardRef");
$RefreshReg$(_c6, "Search");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;